.dixu-customer-experience-card-solid {
    @include themify {
        border-radius: themed('border', 'radius', 'lg');
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__content {
        margin-bottom: 16px;
    }
}

.dixu-customer-experience-card-glass {
    @include themify {
        border: 1px solid themed('color', 2, 4);
    }

    display: flex;
    height: 100%;
    padding: 40px;
    position: relative;

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(238, 237, 249, 0.94), rgba(238, 237, 249, 0.94));
    }

    &__content {
        flex: 1 1 auto;
    }
}

.dixu-customer-experience-card-group {
    &__card-wrapper {
        flex: 1 0 100%;

        & > * {
            flex: 1 1 auto;
        }

        @include bp-tablet {
            &--half-width {
                flex: 1 0 50%;
            }
        }
    }
}
