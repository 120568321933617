.dixu-listing-preview-card {
    overflow: hidden;
    position: relative;
    top: 0;

    @include themify {
        border-radius: themed('border', 'radius', 'lg');
        transition: all themed('transition', 'speed', 'md');
        transition-property: top, box-shadow, border;
    }

    &:focus {
        @include themify {
            box-shadow: 0 0 0 8px rgba(themed('color', 1, 5), 0.15);
        }
    }

    &:hover {
        @include bp-tablet {
            @include themify {
                box-shadow: themed('shadow', 3);
            }

            top: -8px;
        }
    }

    &:active {
        @include themify {
            box-shadow: themed('shadow', 2) inset;
        }
    }

    &__image-wrapper {
        position: relative;
    }

    &__next-presentation {
        position: absolute;
        left: 8px;
        bottom: 8px;
        max-width: calc(100% - 16px);
        overflow: hidden;

        @include themify {
            border-radius: themed('border', 'radius', 'sm');
        }
    }

    &__overlay-bg {
        opacity: 0.9;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__overlay-text {
        position: relative;
        z-index: 1;
    }
}
