.dixu-service-package-card {
    position: relative;
    height: 100%;
    padding-top: 44px;
    z-index: 0;

    &__decoration {
        @include themify {
            filter: drop-shadow(themed('shadow', 1));
        }

        user-select: none;
        position: absolute;
        bottom: 0;
        z-index: 1;

        &--seller {
            left: 0;
            width: 75px;
            height: 128px;
            transform: translate(-29%, 6px);

            @include bp-tablet {
                width: 136px;
                height: 230px;
                transform: translate(-50%, 12px);
            }

            @include bp-desktop {
                width: 164px;
                height: 278px;
                transform: translate(-50%, 16px);
            }
        }

        &--realtor {
            left: 0;
            width: 36px;
            height: 128px;
            transform: translate(-30%, 6px) scaleX(-1);

            @include bp-tablet {
                left: auto;
                right: 0;
                width: 64px;
                height: 230px;
                transform: translate(50%, 16px);
            }

            @include bp-desktop {
                width: 75px;
                height: 271px;
                transform: translate(50%, 18px);
            }
        }
    }

    &__content {
        @include themify {
            background: themed('color', 2, 1);
            border-radius: themed('border', 'radius', 'lg');
            box-shadow: themed('shadow', 3);
        }

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 0 16px 40px;
    }

    &__package-icon {
        margin-top: -44px;
    }

    &__subtitle {
        margin: 0 auto;
    }
}
