.dixu-radio {
    .ant-radio {
        .ant-radio-inner {
            @include themify {
                background: themed('color', 2, 1);
                border: 1px solid themed('color', 2, 4);
            }
        }

        &:hover {
            .ant-radio-inner {
                @include themify {
                    border: 1px solid themed('color', 1, 5);
                }
            }
        }

        + span {
            // Fix issue where radio button text that didn't fit was not line wrapping
            white-space: pre-line;
            @include themify {
                color: themed('color', 2, 8);
            }
        }
    }

    .ant-radio-checked {
        &::after {
            @include themify {
                border-color: themed('color', 1, 5);
            }
        }

        .ant-radio-inner {
            @include themify {
                border: 1px solid themed('color', 1, 5);
            }

            &::after {
                @include themify {
                    background: themed('color', 1, 5);
                }
            }
        }
    }

    .ant-radio-disabled {
        .ant-radio-inner {
            @include themify {
                background: themed('color', 2, 3);
                border: 1px solid themed('color', 2, 4) !important;
            }

            &::after {
                @include themify {
                    background: themed('color', 2, 6);
                }
            }
        }

        + span {
            @include themify {
                color: themed('color', 2, 6);
            }
        }
    }
}

.dixu-radio-group {
    @extend .dixu-radio;

    &--vertical {
        @include important {
            display: inline-flex;
            flex-direction: column;
        }
    }

    &--columned {
        .dixu-radio {
            display: inline-flex;
            align-items: center;
            width: 100%;
        }
    }

    &--scroll-overflow {
        &.ant-radio-group-outline,
        &.ant-radio-group-solid {
            display: inline-flex;
            overflow: auto;
            max-width: 100%;
        }
    }

    &.ant-radio-group-outline,
    &.ant-radio-group-solid {
        .ant-radio-button-wrapper {
            flex: 0 0 auto;
            height: auto;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 6px 16px;

            &:not(.ant-radio-button-wrapper-disabled):focus-within {
                box-shadow: none;
            }

            &:first-child {
                @include themify {
                    border-top-left-radius: themed('border', 'radius', 'md');
                    border-bottom-left-radius: themed('border', 'radius', 'md');
                }
            }

            &:last-child {
                @include themify {
                    border-top-right-radius: themed('border', 'radius', 'md');
                    border-bottom-right-radius: themed('border', 'radius', 'md');
                }
            }
        }
    }

    &.ant-radio-group-large {
        .ant-radio-wrapper {
            @include themify {
                font: 500 themed('font', 'quicksand', 'desktop', 3);
            }
        }

        .ant-radio-button-wrapper {
            @include themify {
                font: 500 themed('font', 'quicksand', 'desktop', 3);
            }

            min-height: 40px;

            &:first-child {
                @include themify {
                    border-top-left-radius: themed('border', 'radius', 'lg');
                    border-bottom-left-radius: themed('border', 'radius', 'lg');
                }
            }

            &:last-child {
                @include themify {
                    border-top-right-radius: themed('border', 'radius', 'lg');
                    border-bottom-right-radius: themed('border', 'radius', 'lg');
                }
            }
        }
    }

    &.ant-radio-group-outline {
        .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled) {
            @include themify {
                border-color: themed('color', 2, 4);
                color: themed('color', 2, 8);
            }

            &::before {
                @include themify {
                    background-color: themed('color', 2, 4);
                }
            }

            &:hover {
                @include themify {
                    border-color: themed('color', 1, 5);
                    color: themed('color', 1, 5);
                }

                &:not(.ant-radio-button-wrapper-disabled)::before {
                    @include themify {
                        background-color: themed('color', 1, 5);
                    }
                }

                &:not(.ant-radio-button-wrapper-disabled) + .ant-radio-button-wrapper::before {
                    @include themify {
                        background-color: themed('color', 1, 5);
                    }
                }
            }

            &:active {
                @include themify {
                    border-color: themed('color', 2, 4);
                    color: themed('color', 1, 7);
                }

                &:not(.ant-radio-button-wrapper-disabled)::before {
                    @include themify {
                        background-color: themed('color', 2, 4);
                    }
                }

                &:not(.ant-radio-button-wrapper-disabled) + .ant-radio-button-wrapper::before {
                    @include themify {
                        background-color: themed('color', 2, 4);
                    }
                }
            }
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            @include themify {
                border-color: themed('color', 1, 6);
                color: themed('color', 1, 6);
            }

            &::before {
                @include themify {
                    background-color: themed('color', 1, 6);
                }
            }
        }

        .ant-radio-button-wrapper-disabled:not(.ant-radio-button-wrapper-checked) {
            @include themify {
                border-color: themed('color', 2, 4);
                color: themed('color', 2, 6);
                background: themed('color', 2, 3);
            }

            &::before {
                @include themify {
                    background-color: themed('color', 2, 4);
                }
            }
        }

        .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled {
            @include themify {
                border-color: themed('color', 2, 4);
                color: themed('color', 2, 1);
                background: themed('color', 2, 4);
            }

            &::before {
                @include themify {
                    background-color: themed('color', 2, 4);
                }
            }
        }
    }

    &.ant-radio-group-solid {
        .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):not(.ant-radio-button-wrapper-disabled) {
            @include themify {
                border-color: themed('color', 2, 4);
                color: themed('color', 2, 8);
            }

            &::before {
                @include themify {
                    background-color: themed('color', 2, 4);
                }
            }

            &:hover {
                @include themify {
                    border-color: themed('color', 1, 5);
                    color: themed('color', 1, 5);
                }

                &::before {
                    @include themify {
                        background-color: themed('color', 1, 5);
                    }
                }

                & + .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked)::before {
                    @include themify {
                        background-color: themed('color', 1, 5);
                    }
                }
            }

            &:active {
                @include themify {
                    border-color: themed('color', 2, 4);
                    color: themed('color', 1, 7);
                }

                &:not(.ant-radio-button-wrapper-disabled)::before {
                    @include themify {
                        background-color: themed('color', 2, 4);
                    }
                }

                &:not(.ant-radio-button-wrapper-disabled) + .ant-radio-button-wrapper::before {
                    @include themify {
                        background-color: themed('color', 2, 4);
                    }
                }
            }
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            @include primary-button-overlay;

            @include themify {
                color: themed('color', 2, 1);
                background: themed('color', 1, 6);
            }

            @include important(1, '.ant-radio-button-wrapper-checked') {
                border-right-color: transparent;
            }

            position: relative;
            border-color: transparent;

            &::before {
                @include themify {
                    background: transparent;
                }
            }

            & + .ant-radio-button-wrapper::before {
                left: 0;
            }

            &:hover {
                @include themify {
                    color: themed('color', 2, 1);
                    border-color: transparent;
                    background: themed('color', 1, 5);
                }
            }

            &:active {
                @include themify {
                    background: themed('color', 2, 7);
                }
            }
        }

        .ant-radio-button-wrapper-disabled:not(.ant-radio-button-wrapper-checked) {
        }

        .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled {
        }
    }

    &--separated {
        @include important {
            margin: -8px 0 0 -8px;
        }

        &.ant-radio-group-outline,
        &.ant-radio-group-solid {
            .ant-radio-button-wrapper {
                @include themify {
                    border-radius: themed('border', 'radius', 'md');
                }

                margin: 8px 0 0 8px;
                border-width: 1px;

                &::before {
                    content: none;
                }
            }

            &.ant-radio-group-large {
                .ant-radio-button-wrapper {
                    @include themify {
                        border-radius: themed('border', 'radius', 'lg');
                    }
                }
            }
        }
    }

    &--block {
        @include important(2) {
            display: flex;
        }

        .ant-radio-button-wrapper {
            @include important {
                flex: 1 0 auto;
            }
        }
    }

    &--align-center {
        @include important {
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}

.dixu-radio-group-wrapper {
    display: contents;
}
