.dixu-tabs {
    .ant-tabs-nav {
        @include important(1, '.ant-tabs-nav') {
            &::before {
                border: 0;
            }
        }
    }

    .ant-tabs-tab {
        &:nth-last-child(2) {
            margin-right: 0;
        }

        .ant-tabs-tab-btn {
            @include important {
                font-weight: 500;
            }

            @include themify {
                color: themed('color', 2, 8);
            }
        }
    }

    .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            @include themify {
                color: themed('color', 1, 6);
                font-weight: 700;
            }
        }
    }

    .ant-tabs-ink-bar {
        @include themify {
            background: themed('color', 1, 7);
        }
    }

    .ant-tabs-nav-operations {
        @include important(2) {
            display: none;
        }
    }

    &--allow-overflow {
        @include important {
            overflow: visible;
        }
    }

    &--no-tab-bar-margin {
        @include important {
            .ant-tabs-nav {
                margin-bottom: 0;
            }
        }
    }

    &__tab-number {
        @include themify {
            background: themed('color', 2, 1);
            border: 1px solid themed('color', 2, 6);
            color: themed('color', 2, 6);
            transition: all themed('transition', 'speed', 'md');
            transition-property: color, border, background;
        }

        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: 8px;
        font-weight: 500;
    }

    .ant-tabs-tab-active &__tab-number {
        @include themify {
            background: themed('color', 1, 6);
            border: 1px solid themed('color', 1, 6);
            color: themed('color', 2, 1);
        }
    }

    &.ant-tabs-card {
        @include themify {
            border: 1px solid themed('color', 2, 4);
            border-radius: themed('border', 'radius', 'md');
            overflow: hidden;
        }

        @include border-radius-overflow-fix;

        .ant-tabs-nav {
            margin-bottom: 0;
        }

        .ant-tabs-nav-wrap {
            overflow-x: auto !important;
        }

        .ant-tabs-nav-list {
            flex: 1 1 auto;
        }

        .ant-tabs-tab {
            margin-left: 0 !important;

            @include themify {
                background: themed('color', 2, 3);
                border: 0;
                border-bottom: 1px solid themed('color', 2, 4);

                &:not(:first-child) {
                    border-left: 1px solid themed('color', 2, 4);
                }
            }

            @include important(3) {
                margin-right: 0;
                border-radius: 0;
                padding: 16px;
            }

            flex: 1 1 auto;
            justify-content: center;
            text-align: center;
        }

        .ant-tabs-tab-active {
            @include themify {
                background: themed('color', 2, 2);
            }

            @include important(2) {
                border-bottom: 1px solid transparent;
            }

            .ant-tabs-tab-btn {
                @include themify {
                    color: themed('color', 2, 8);
                }
            }
        }

        .ant-tabs-tab-disabled {
            .ant-tabs-tab-btn {
                opacity: 0.5;
            }
        }

        .ant-tabs-content-holder {
            @include themify {
                background: themed('color', 2, 2);
            }

            padding: 24px;
        }
    }
}
