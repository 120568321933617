@mixin block-button {
    display: flex;
    width: 100%;
}

@mixin inline-button {
    display: inline-flex;
}

@mixin focused-button(
    $color: (
        1,
        2,
    )
) {
    &::before {
        @include themify {
            box-shadow: 0 0 0 8px rgba(themed('color', $color...), 0.2);
        }

        position: absolute;
        content: '';
        display: block;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border-radius: inherit;
        z-index: 1;
    }
}

.dixu-button {
    @include inline-button;

    @include themify {
        transition: all themed('transition', 'speed', 'md');
    }

    text-decoration: none;

    position: relative;
    align-items: center;
    text-align: center;
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    border: 0;
    outline: 0;

    & * {
        img,
        svg {
            max-width: 100%;
        }
    }

    @at-root {
        button#{&},
        a#{&} {
            &:hover {
                cursor: pointer;
            }
        }
    }

    &:disabled {
        &:hover {
            cursor: not-allowed;
        }
    }

    &--loading:disabled {
        &:hover {
            cursor: wait;
        }
    }

    &--justify-start {
        justify-content: flex-start;
    }

    &--justify-center {
        justify-content: center;
    }

    &--justify-end {
        justify-content: flex-end;
    }

    &--block-true {
        @include block-button;
    }

    &--block-mobile {
        @include bp-mobile-all-only {
            @include block-button;
        }
    }

    &--block-tablet {
        @include bp-tablet-all-only {
            @include block-button;
        }
    }

    &--block-desktop {
        @include bp-desktop {
            @include block-button;
        }
    }

    &--type-primary {
        @include primary-button-overlay;

        @include themify {
            color: themed('color', 2, 1);
            background: themed('color', 1, 6);
        }

        border: 1px solid transparent;

        &:hover {
            @include themify {
                background: themed('color', 1, 5);
            }
        }

        &:focus:not(:active) {
            @include focused-button;

            @include themify {
                background: themed('color', 1, 5);
            }
        }

        &:active {
            @include themify {
                background: themed('color', 1, 7);
            }
        }

        &:disabled {
            @include themify {
                color: themed('color', 2, 6);
                background: themed('color', 2, 3);
                border: 1px solid themed('color', 2, 4);
            }
        }
    }

    &--type-danger {
        @include primary-button-overlay;

        @include themify {
            color: themed('color', 2, 1);
            background: themed('color', 3, 5);
        }

        border: 1px solid transparent;

        &:hover {
            @include themify {
                background: themed('color', 3, 4);
            }
        }

        &:focus:not(:active) {
            @include focused-button(
                $color: (
                    3,
                    2,
                )
            );

            @include themify {
                background: themed('color', 3, 4);
            }
        }

        &:active {
            @include themify {
                background: themed('color', 3, 7);
            }
        }

        &:disabled {
            @include themify {
                color: themed('color', 2, 6);
                background: themed('color', 2, 3);
                border: 1px solid themed('color', 2, 4);
            }
        }
    }

    &--type-outline {
        @include themify {
            color: themed('color', 2, 8);
            background: themed('color', 2, 1);
            border: 1px solid themed('color', 2, 4);
        }

        &:hover {
            @include themify {
                color: themed('color', 1, 5);
                border: 1px solid themed('color', 1, 5);
            }
        }

        @at-root .ant-pagination-item-active & {
            @include themify {
                color: themed('color', 1, 5);
                border: 1px solid themed('color', 1, 5);
            }
        }

        &:focus:not(:active) {
            @include focused-button;

            @include themify {
                color: themed('color', 1, 5);
                border: 1px solid themed('color', 1, 5);
            }
        }

        &:active {
            @include themify {
                color: themed('color', 1, 7);
                border: 1px solid themed('color', 1, 7);
            }
        }

        &:disabled {
            @include themify {
                color: themed('color', 2, 6);
                background: themed('color', 2, 3);
                border: 1px solid themed('color', 2, 4);
            }
        }
    }

    &--type-ghost {
        background: transparent;

        @include themify {
            color: themed('color', 1, 6);
            border: 1px solid themed('color', 1, 6);
        }

        &:hover,
        &:focus:not(:active) {
            @include themify {
                color: themed('color', 1, 5);
                border: 1px solid themed('color', 1, 5);
            }
        }

        &:active {
            @include themify {
                color: themed('color', 1, 7);
                border: 1px solid themed('color', 1, 7);
            }
        }

        &:disabled {
            @include themify {
                color: themed('color', 2, 6);
                border: 1px solid themed('color', 2, 4);
            }

            opacity: 0.5;
        }
    }

    &--type-link {
        @include themify {
            color: themed('color', 2, 8);
        }

        background: transparent;

        &:hover {
            @include themify {
                color: themed('color', 1, 5);
            }
        }

        &:focus:not(:active) {
            @include focused-button;

            @include themify {
                color: themed('color', 1, 5);
            }
        }

        &:active {
            @include themify {
                color: themed('color', 1, 7);
            }
        }

        &:disabled {
            @include themify {
                color: themed('color', 2, 6);
            }
        }
    }

    &--type-white {
        @include themify {
            color: themed('color', 2, 1);
        }
    }

    &--size-sm {
        @include themify {
            border-radius: themed('border', 'radius', 'sm');
            font: themed('font', 'quicksand', 'desktop', 2);
        }

        padding: 0 16px;
        min-height: 22px;
    }

    &--size-sm#{&}--modifier-type-circle {
        padding: 0;
        width: 24px;
        height: 24px;
    }

    &--size-md {
        @include themify {
            border-radius: themed('border', 'radius', 'md');
            font: themed('font', 'quicksand', 'desktop', 2);
        }

        padding: 3px 24px;
        min-height: 32px;
    }

    &--size-md#{&}--modifier-type-circle {
        padding: 0;
        width: 32px;
        height: 32px;
    }

    &--size-lg {
        @include themify {
            border-radius: themed('border', 'radius', 'lg');
            font: themed('font', 'quicksand', 'desktop', 3);
        }

        padding: 8px 32px;
        min-height: 40px;
    }

    &--size-xl {
        @include themify {
            border-radius: themed('border', 'radius', 'lg');
            font: themed('font', 'quicksand', 'desktop', 3);
        }

        padding: 8px 32px;
        min-height: 72px;
    }

    &--size-xl &__icon {
        @include themify {
            border-radius: themed('border', 'radius', 'lg');
            font: themed('font', 'quicksand', 'desktop', 5);
        }
    }

    &--weight {
        &-light {
            @include important {
                font-weight: 300;
            }

            svg {
                stroke-width: 0;
            }
        }

        &-normal {
            @include important {
                font-weight: 400;
            }

            svg {
                stroke-width: 0;
            }
        }

        &-medium {
            @include important {
                font-weight: 500;
            }

            svg {
                stroke-width: 0;
            }
        }

        &-semibold {
            @include important {
                font-weight: 600;
            }

            svg {
                stroke-width: 0;
            }
        }

        &-bold {
            @include important {
                font-weight: 700;
            }

            svg {
                stroke: currentColor;
                stroke-width: 16px;
            }
        }

        &-heavy {
            @include important {
                font-weight: 900;
            }

            svg {
                stroke: currentColor;
                stroke-width: 16px;
            }
        }
    }

    &--size-md#{&}--modifier-type-circle {
        width: 40px;
        height: 40px;
    }

    &--size-md#{&}--icon-only {
        font-size: 20px;
    }

    &--size-lg#{&}--icon-only {
        font-size: 28px;
        width: 56px;
        height: 56px;
    }

    &--size-lg#{&}--modifier-type-circle {
        width: 64px;
        height: 64px;
    }

    &--icon-only {
        padding: 0;
    }

    &--modifier-type-circle {
        @include important {
            border-radius: 50%;
        }
    }

    &--with-icon {
        text-align: left;
    }

    &--wrap-icon {
        flex-wrap: wrap;
        text-align: center;
    }

    &--no-border {
        @include important {
            border: 0;
        }
    }

    &--no-padding {
        padding: 0;
        min-height: auto;
        min-width: auto;
    }

    &__text {
        display: flex;
        align-items: center;
    }

    &--ellipsis &__text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &--reverse-icon {
        flex-direction: row-reverse;
    }

    &__icon {
        padding-right: 4px;
        padding-left: 4px;
        flex: 0 0 auto;
        pointer-events: none;

        & + * {
            padding-right: 4px;
            padding-left: 4px;
        }
    }

    &__badge {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        z-index: 1;
    }
}

.dixu-button-group {
    display: flex;
    flex-wrap: wrap;

    &--spacing-sm {
        margin-top: -8px;
        margin-left: -8px;

        & > *,
        .ant-modal-footer & button + button {
            margin-top: 8px;
            margin-left: 8px;
        }
    }

    &--spacing-md {
        margin-top: -16px;
        margin-left: -16px;

        & > *,
        .ant-modal-footer & button + button {
            margin-top: 16px;
            margin-left: 16px;
        }
    }

    &--spacing-lg {
        margin-top: -16px;
        margin-left: -24px;

        & > *,
        .ant-modal-footer & button + button {
            margin-top: 16px;
            margin-left: 24px;
        }
    }

    &--spacing-xl {
        margin-top: -16px;
        margin-left: -32px;

        & > *,
        .ant-modal-footer & button + button {
            margin-top: 16px;
            margin-left: 32px;
        }
    }

    &--justify-start {
        justify-content: flex-start;
    }

    &--justify-center {
        justify-content: center;
    }

    &--justify-end {
        justify-content: flex-end;
    }
}
