.dixu-text {
    &--ellipsis {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &--weight-light {
        @include important {
            font-weight: 300;
        }

        & .dixu-text:not([class*='dixu-text--weight']) {
            @include important {
                font-weight: 300;
            }
        }

        & > svg,
        & > .anticon > svg {
            stroke-width: 0;
        }
    }

    &--weight-normal {
        @include important {
            font-weight: 400;
        }

        & .dixu-text:not([class*='dixu-text--weight']) {
            @include important {
                font-weight: 400;
            }
        }

        & > svg,
        & > .anticon > svg {
            stroke-width: 0;
        }
    }

    &--weight-medium {
        @include important {
            font-weight: 500;
        }

        & .dixu-text:not([class*='dixu-text--weight']) {
            @include important {
                font-weight: 500;
            }
        }

        & > svg,
        & > .anticon > svg {
            stroke-width: 0;
        }
    }

    &--weight-semibold {
        @include important {
            font-weight: 600;
        }

        & .dixu-text:not([class*='dixu-text--weight']) {
            @include important {
                font-weight: 600;
            }
        }

        & > svg,
        & > .anticon > svg {
            stroke: currentColor;
            stroke-width: 8px;
        }
    }

    &--weight-bold {
        @include important {
            font-weight: 700;
        }

        & .dixu-text:not([class*='dixu-text--weight']) {
            @include important {
                font-weight: 700;
            }
        }

        & > svg,
        & > .anticon > svg {
            stroke: currentColor;
            stroke-width: 16px;
        }
    }

    &--weight-heavy {
        @include important {
            font-weight: 900;
        }

        & .dixu-text:not([class*='dixu-text--weight']) {
            @include important {
                font-weight: 900;
            }
        }

        & > svg,
        & > .anticon > svg {
            stroke: currentColor;
            stroke-width: 24px;
        }
    }

    &--align-left {
        text-align: left;
    }

    &--align-center {
        display: block;
        text-align: center;
    }

    &--align-right {
        display: block;
        text-align: right;
    }

    &--hyphens {
        hyphens: auto;
    }

    &--flexbox {
        display: inline-flex;
    }

    &--block {
        display: block;
    }

    &--flexbox#{&}--block {
        display: flex;
    }

    &--wrap {
        overflow-wrap: anywhere;
    }

    &--decoration-underline {
        text-decoration: underline;
    }

    &--white-space {
        &-normal {
            white-space: normal;
        }

        &-pre-line {
            white-space: pre-line;
        }

        &-pre-wrap {
            white-space: pre-wrap;
        }
    }

    &--transform-capitalize {
        text-transform: capitalize;
    }

    &--transform-uppercase {
        text-transform: uppercase;
    }

    &--decoration-underline {
        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }

    @at-root a#{&} {
        &--decoration-underline {
            &:hover,
            &:focus,
            &:active {
                text-decoration: underline;
            }
        }
    }

    ul {
        list-style-type: disc;
        list-style-position: inside;
    }

    strong {
        font-weight: bold;
    }
}
