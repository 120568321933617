.dixu-main-footer {
    @include themify {
        background: themed('color', 2, 9);
    }

    &__content {
        display: grid;
        grid-template-rows: repeat(4, auto);
        grid-template-columns: 1fr;
        gap: 64px;
        align-items: start;
        justify-items: center;
        max-width: 306px;
        margin: 0 auto;
        padding: 40px 0;

        @include bp-desktop {
            grid-template-rows: auto;
            grid-template-columns: repeat(3, 1fr);
            gap: 128px;
            justify-items: start;
            max-width: none;
            padding: 80px 0;
        }
    }

    &__social-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include bp-desktop {
            grid-row: 1;
            grid-column: 2;
            justify-self: center;
        }
    }

    &__logo-wrapper {
        flex: 0 0 auto;
        margin-bottom: 32px;

        img,
        svg {
            display: block;
            height: 56px;
            max-width: 100%;
        }

        svg * {
            @include themify {
                fill: themed('color', 2, 1);
            }
        }
    }

    &__social-links {
        @include bp-desktop {
            margin-bottom: 32px;
        }
    }

    &__social-link {
        margin-top: 16px;
        margin-left: 24px;
    }

    &__nav-links {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include bp-desktop {
            grid-row: 1;
            grid-column: 1;
            flex-direction: row;
            align-items: flex-start;
        }
    }

    &__nav-item-group {
        text-align: center;

        &:not(:last-child) {
            margin-bottom: 40px;
        }

        @include bp-desktop {
            text-align: left;
            margin-bottom: 0;

            &:not(:last-child) {
                margin-right: 80px;
            }
        }
    }

    &__nav-item {
        &:not(:last-child) {
            margin-bottom: 24px;
        }

        @include bp-desktop {
            margin-bottom: 16px;
        }
    }

    &__nav-link {
        text-align: center;

        @include bp-desktop {
            text-align: left;
        }
    }

    &__company-info {
        max-width: 200px;
        margin-bottom: 0;

        @include bp-desktop {
            grid-row: 1;
            grid-column: 3;
            justify-self: end;
            max-width: none;
        }
    }

    &__legal-info {
        @include bp-desktop {
            display: none;
        }

        img,
        svg {
            max-height: 64px;
        }

        &--desktop {
            display: none;

            @include bp-desktop {
                display: block;
            }
        }
    }
}
