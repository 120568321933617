@mixin bp-mobile-small-only {
    @media screen and (max-width: map-get($breakpoints, 'mobile-md') - em(1px)) {
        @content;
    }
}

@mixin bp-mobile-md {
    @media screen and (min-width: map-get($breakpoints, 'mobile-md')) {
        @content;
    }
}

@mixin bp-mobile-md-only {
    @media screen and (min-width: map-get($breakpoints, 'mobile-md')) and (max-width: map-get($breakpoints, 'mobile-lg') - em(1px)) {
        @content;
    }
}

@mixin bp-mobile-lg {
    @media screen and (min-width: map-get($breakpoints, 'mobile-lg')) {
        @content;
    }
}

@mixin bp-mobile-lg-only {
    @media screen and (max-width: map-get($breakpoints, 'mobile-lg')) and (max-width: map-get($breakpoints, 'tablet') - em(1px)) {
        @content;
    }
}

@mixin bp-mobile-all-only {
    @media screen and (max-width: map-get($breakpoints, 'tablet') - em(1px)) {
        @content;
    }
}

@mixin bp-tablet {
    @media screen and (min-width: map-get($breakpoints, 'tablet')) {
        @content;
    }
}

@mixin bp-tablet-only {
    @media screen and (min-width: map-get($breakpoints, 'tablet')) and (max-width: map-get($breakpoints, 'tablet-md') - em(1px)) {
        @content;
    }
}

@mixin bp-tablet-md {
    @media screen and (min-width: map-get($breakpoints, 'tablet-md')) {
        @content;
    }
}

@mixin bp-tablet-md-only {
    @media screen and (min-width: map-get($breakpoints, 'tablet-md')) and (max-width: map-get($breakpoints, 'desktop') - em(1px)) {
        @content;
    }
}

@mixin bp-tablet-all-only {
    @media screen and (min-width: map-get($breakpoints, 'tablet')) and (max-width: map-get($breakpoints, 'desktop') - em(1px)) {
        @content;
    }
}

@mixin bp-mobile-and-tablet-all-only {
    @media screen and (max-width: map-get($breakpoints, 'desktop') - em(1px)) {
        @content;
    }
}

@mixin bp-desktop {
    @media screen and (min-width: map-get($breakpoints, 'desktop')) {
        @content;
    }
}

@mixin bp-desktop-only {
    @media screen and (min-width: map-get($breakpoints, 'desktop')) and (max-width: map-get($breakpoints, 'desktop-md') - em(1px)) {
        @content;
    }
}

@mixin bp-desktop-md {
    @media screen and (min-width: map-get($breakpoints, 'desktop-md')) {
        @content;
    }
}

@mixin bp-desktop-md-only {
    @media screen and (min-width: map-get($breakpoints, 'desktop-md')) and (max-width: map-get($breakpoints, 'desktop-lg') - em(1px)) {
        @content;
    }
}

@mixin bp-desktop-lg {
    @media screen and (min-width: map-get($breakpoints, 'desktop-lg')) {
        @content;
    }
}

@mixin bp-desktop-lg-only {
    @media screen and (min-width: map-get($breakpoints, 'desktop-lg')) and (max-width: map-get($breakpoints, 'desktop-xl') - em(1px)) {
        @content;
    }
}

@mixin bp-desktop-xl {
    @media screen and (min-width: map-get($breakpoints, 'desktop-xl')) {
        @content;
    }
}

@mixin bp-desktop-xl-only {
    @media screen and (min-width: map-get($breakpoints, 'desktop-xl')) and (max-width: map-get($breakpoints, 'desktop-xxl') - em(1px)) {
        @content;
    }
}

@mixin bp-desktop-xxl {
    @media screen and (min-width: map-get($breakpoints, 'desktop-xxl')) {
        @content;
    }
}

@mixin bp-desktop-all-only {
    @media screen and (min-width: map-get($breakpoints, 'desktop')) {
        @content;
    }
}

@mixin bp($min: null, $max: null) {
    @if $min and $max {
        @media screen and (min-width: $min) and (max-width: $max - em(1px)) {
            @content;
        }
    } @else if $min {
        @media screen and (min-width: $min) {
            @content;
        }
    } @else if $max {
        @media screen and (max-width: $max - em(1px)) {
            @content;
        }
    }
}

@mixin important($importance: 1, $target: &) {
    $suffix: nth(nth($target, -1), -1);

    @for $i from 1 to $importance {
        $suffix: $suffix + $suffix;
    }

    @at-root &#{$suffix} {
        @content;
    }
}

@mixin themify($parent: &) {
    @each $theme, $map in $themes {
        @at-root [data-theme='#{$theme}'] #{$parent} {
            @each $key, $submap in $map {
                $theme-map: map-get($themes, $theme) !global;
            }

            @content;

            $theme-map: null !global;
        }
    }
}

@mixin theme-loop(
    $selectorPrefix,
    $styleName,
    $path,
    $selectorTemplate: '%PREFIX%-%KEY%',
    $valueTemplate: '%VALUE%'
) {
    @include themify('') {
        @each $key, $value in themed($path...) {
            $stylePath: append($path, $key);

            @if type-of($value) == 'map' {
                @include theme-loop(
                    $selectorPrefix: #{$selectorPrefix}-#{$key},
                    $styleName: $styleName,
                    $path: $stylePath,
                    $selectorTemplate: $selectorTemplate,
                    $valueTemplate: $valueTemplate
                );
            } @else {
                #{unquote(str-replace(str-replace($selectorTemplate, '%PREFIX%', $selectorPrefix), '%KEY%', $key))} {
                    #{$styleName}: unquote(
                        str-replace($valueTemplate, '%VALUE%', themed($stylePath...))
                    );
                    @content;
                }
            }
        }
    }
}

@mixin vhidden {
    position: absolute !important;
    left: -10000px !important;
    top: auto !important;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden !important;
}

// Fix border radius overflow on Safari.
@mixin border-radius-overflow-fix {
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
}

@mixin button-reset {
    background: transparent;
    border: 0;
    text-align: left;
    padding: 0;
    cursor: pointer;
    outline: 0;
}

@mixin primary-button-overlay($borderWidth: 1px) {
    position: relative;

    &:not(:disabled)::after {
        @include themify {
            transition: box-shadow themed('transition', 'speed', 'md');
        }

        content: '';
        position: absolute;
        top: -$borderWidth;
        left: -$borderWidth;
        width: calc(100% + #{2 * $borderWidth});
        height: calc(100% + #{2 * $borderWidth});
        border-radius: inherit;
        pointer-events: none;
        box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.15),
            inset 0px 2px 0px rgba(255, 255, 255, 0.15);
    }

    &:active::after {
        box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.15),
            inset 0px -2px 0px rgba(255, 255, 255, 0.15);
    }
}

// https://stackoverflow.com/a/32455002
@mixin firefox-only {
    @supports (-moz-appearance: none) {
        @content;
    }
}

// https://browserstrangeness.bitbucket.io/css_hacks.html#safari
@mixin safari-only {
    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
            @content;
        }
    }
}

// https://blog.teamtreehouse.com/increase-your-sites-performance-with-hardware-accelerated-css
@mixin enforce-hardware-acceleration {
    transform: translate3d(0, 0, 0);
}
