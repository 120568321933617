.dixu-error-boundary {
    width: 100%;

    &__message {
        @include themify {
            background: themed('color', 2, 2);
        }

        overflow: auto;
        max-height: 10rem;
        padding: 8px;
    }
}
