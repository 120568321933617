/**
 * Utility classes defined in this file are imported after component and screen
 * specific styles so these take precedence.
 */

/**
 * Accessibility.
 */

.vhidden {
    @include vhidden;
}

/**
 * Visibility.
 */

.mobile-only {
    @include bp-tablet-all-only {
        display: none !important;
    }

    @include bp-desktop-all-only {
        display: none !important;
    }
}

.tablet-only {
    @include bp-mobile-all-only {
        display: none !important;
    }

    @include bp-desktop-all-only {
        display: none !important;
    }
}

.desktop-only {
    @include bp-mobile-all-only {
        display: none !important;
    }

    @include bp-tablet-all-only {
        display: none !important;
    }
}

.mobile-hidden {
    @include bp-mobile-all-only {
        display: none !important;
    }
}

.tablet-hidden {
    @include bp-tablet-all-only {
        display: none !important;
    }
}

.desktop-hidden {
    @include bp-desktop-all-only {
        display: none !important;
    }
}

/**
 * Margin and padding.
 */

$max: 160;
$offset: 2;
$unit: 'px';

@mixin list-loop($className, $styleName) {
    $i: 0;
    @while $i <= $max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }
        $i: $i + $offset;
    }
}

@include list-loop('.m-', 'margin');
@include list-loop('.m-l-', 'margin-left');
@include list-loop('.m-r-', 'margin-right');
@include list-loop('.m-b-', 'margin-bottom');
@include list-loop('.m-t-', 'margin-top');
@include list-loop('.p-', 'padding');
@include list-loop('.p-l-', 'padding-left');
@include list-loop('.p-r-', 'padding-right');
@include list-loop('.p-t-', 'padding-top');
@include list-loop('.p-b-', 'padding-bottom');

@mixin mobile-list-loop($className, $styleName) {
    @include bp-mobile-all-only {
        $i: 0;
        @while $i <= $max {
            #{$className + $i} {
                #{$styleName}: #{$i + $unit};
            }
            $i: $i + $offset;
        }
    }
}

@include mobile-list-loop('.mobile-m-', 'margin');
@include mobile-list-loop('.mobile-m-l-', 'margin-left');
@include mobile-list-loop('.mobile-m-r-', 'margin-right');
@include mobile-list-loop('.mobile-m-b-', 'margin-bottom');
@include mobile-list-loop('.mobile-m-t-', 'margin-top');
@include mobile-list-loop('.mobile-p-', 'padding');
@include mobile-list-loop('.mobile-p-l-', 'padding-left');
@include mobile-list-loop('.mobile-p-r-', 'padding-right');
@include mobile-list-loop('.mobile-p-t-', 'padding-top');
@include mobile-list-loop('.mobile-p-b-', 'padding-bottom');

@mixin tablet-list-loop($className, $styleName) {
    @include bp-tablet-all-only {
        $i: 0;
        @while $i <= $max {
            #{$className + $i} {
                #{$styleName}: #{$i + $unit};
            }
            $i: $i + $offset;
        }
    }
}

@include tablet-list-loop('.tablet-m-', 'margin');
@include tablet-list-loop('.tablet-m-l-', 'margin-left');
@include tablet-list-loop('.tablet-m-r-', 'margin-right');
@include tablet-list-loop('.tablet-m-b-', 'margin-bottom');
@include tablet-list-loop('.tablet-m-t-', 'margin-top');
@include tablet-list-loop('.tablet-p-', 'padding');
@include tablet-list-loop('.tablet-p-l-', 'padding-left');
@include tablet-list-loop('.tablet-p-r-', 'padding-right');
@include tablet-list-loop('.tablet-p-t-', 'padding-top');
@include tablet-list-loop('.tablet-p-b-', 'padding-bottom');

@mixin desktop-list-loop($className, $styleName) {
    @include bp-desktop-all-only {
        $i: 0;
        @while $i <= $max {
            #{$className + $i} {
                #{$styleName}: #{$i + $unit};
            }
            $i: $i + $offset;
        }
    }
}

@include desktop-list-loop('.desktop-m-', 'margin');
@include desktop-list-loop('.desktop-m-l-', 'margin-left');
@include desktop-list-loop('.desktop-m-r-', 'margin-right');
@include desktop-list-loop('.desktop-m-b-', 'margin-bottom');
@include desktop-list-loop('.desktop-m-t-', 'margin-top');
@include desktop-list-loop('.desktop-p-', 'padding');
@include desktop-list-loop('.desktop-p-l-', 'padding-left');
@include desktop-list-loop('.desktop-p-r-', 'padding-right');
@include desktop-list-loop('.desktop-p-t-', 'padding-top');
@include desktop-list-loop('.desktop-p-b-', 'padding-bottom');
