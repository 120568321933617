.dixu-select {
    max-width: 100%;

    .ant-select-selector {
        @include themify {
            @include important {
                border-radius: themed('border', 'radius', 'md');
                border: 1px solid themed('color', 2, 4);
                background: themed('color', 2, 2);
            }
        }
    }

    .ant-select-clear {
        @include themify {
            background: themed('color', 2, 2);
        }
    }

    .ant-select-selection-placeholder {
        @include themify {
            color: themed('color', 2, 8);
        }
    }

    @at-root .dixu-form--color-scheme-reverse & {
        .ant-select-selector {
            @include themify {
                background: themed('color', 2, 1);
            }
        }

        .ant-select-clear {
            @include themify {
                background: themed('color', 2, 1);
            }
        }
    }

    .ant-select-arrow {
        @include themify {
            color: themed('color', 2, 6);
            transition: opacity themed('transition', 'speed', 'md');
        }
    }

    &--has-value {
        &.ant-select-allow-clear:hover {
            .ant-select-arrow {
                opacity: 0;
            }
        }
    }

    .ant-select-selection-item {
        @include themify {
            font: themed('font', 'quicksand', 'desktop', 2);
            font-weight: 500;
            color: themed('color', 2, 8);
        }

        display: flex;
        align-items: center;
    }

    &:hover {
        .ant-select-selector {
            @include themify {
                @include important {
                    border-color: themed('color', 1, 5);
                }
            }
        }
    }

    &.ant-select-focused {
        .ant-select-selector {
            @include themify {
                @include important(2) {
                    border-color: themed('color', 1, 5);
                    box-shadow: themed('shadow', 5);
                }
            }
        }
    }

    &.ant-select-disabled {
        .ant-select-selector {
            @include themify {
                @include important(2) {
                    background: themed('color', 2, 3);
                    border-color: themed('color', 2, 4);
                }
            }
        }

        .ant-select-selection-item {
            @include themify {
                color: themed('color', 2, 6);
            }
        }
    }

    &.ant-select-lg {
        .ant-select-selector {
            @include themify {
                @include important {
                    border-radius: themed('border', 'radius', 'lg');
                }
            }
        }

        .ant-select-selection-item {
            @include themify {
                font: themed('font', 'quicksand', 'desktop', 3);
                font-weight: 500;
            }
        }
    }
}

.dixu-select-dropdown {
    @include themify {
        border-radius: themed('border', 'radius', 'md');
        box-shadow: themed('shadow', 1);
    }

    @include important {
        padding: 0;
    }

    .ant-select-item-option-content {
        @include themify {
            font: themed('font', 'quicksand', 'desktop', 2);
            font-weight: 500;
            color: themed('color', 2, 8);
        }
    }

    .ant-select-item-option-active {
        @include important {
            background: transparent;
        }

        .ant-select-item-option-content {
            @include themify {
                color: themed('color', 1, 5);
            }
        }
    }

    .ant-select-item-option-selected {
        @include themify {
            @include important {
                background: themed('color', 2, 2);
            }
        }
    }

    &--size-large {
        @include themify {
            border-radius: themed('border', 'radius', 'lg');
            box-shadow: themed('shadow', 1);
        }

        .ant-select-item-option-content {
            @include themify {
                font: themed('font', 'quicksand', 'desktop', 3);
                font-weight: 500;
            }
        }
    }
}
