@mixin commonInputStyles {
    @include themify {
        border-radius: themed('border', 'radius', 'md');
        background: themed('color', 2, 2);
        border: 1px solid themed('color', 2, 4);
        color: themed('color', 2, 8);
    }

    max-width: 100%;

    @at-root .ant-form-item-has-error & {
        @include themify {
            background: themed('color', 2, 2);
        }

        &:hover {
            @include themify {
                background: themed('color', 2, 2);
            }
        }
    }

    @at-root .dixu-form--color-scheme-reverse & {
        @include themify {
            background: themed('color', 2, 1);
        }
    }

    &.ant-input-affix-wrapper {
        @at-root .dixu-form--color-scheme-reverse & {
            @include themify {
                background: themed('color', 2, 1);
            }
        }
    }

    &::placeholder {
        @include themify {
            color: themed('color', 2, 6);
        }
    }

    &:hover {
        @include themify {
            border: 1px solid themed('color', 1, 5);
        }
    }

    &:focus {
        @include themify {
            border: 1px solid themed('color', 1, 5);
            box-shadow: themed('shadow', 5);
        }
    }

    &.ant-input-affix-wrapper-focused {
        @include themify {
            box-shadow: themed('shadow', 5);
        }
    }

    &:disabled {
        @include themify {
            background: themed('color', 2, 3);
            border: 1px solid themed('color', 2, 4);
            color: themed('color', 2, 6);
        }
    }

    .ant-input-suffix {
        @include themify {
            color: themed('color', 2, 7);
        }
    }

    .ant-input {
        @include themify {
            background: themed('color', 2, 2);
            color: themed('color', 2, 8);
        }

        @at-root .dixu-form--color-scheme-reverse & {
            @include themify {
                background: themed('color', 2, 1);
            }
        }
    }

    &.ant-input-sm {
        @include themify {
            border-radius: themed('border', 'radius', 'sm');
        }
    }

    &.ant-input-affix-wrapper-sm {
        @include themify {
            border-radius: themed('border', 'radius', 'sm');
        }
    }

    &.ant-input-lg {
        height: 44px;

        @include themify {
            border-radius: themed('border', 'radius', 'lg');
        }
    }

    &.ant-input-affix-wrapper-lg {
        @include themify {
            border-radius: themed('border', 'radius', 'lg');
        }
    }

    &--align-right {
        &,
        .ant-input {
            text-align: right;
        }
    }
}

.dixu-input {
    @include commonInputStyles;
}

.dixu-text-area {
    @include commonInputStyles;

    resize: none;

    &--resizable {
        @include bp-desktop {
            @include themify {
                @include important {
                    border-bottom-right-radius: themed('border', 'radius', 'xs');
                }
            }

            resize: vertical !important;
            overflow: auto;
        }
    }
}

.dixu-input-wrapper {
    display: flex;

    & > * {
        flex: 1 1 auto;
        display: flex;
    }

    & > *:nth-child(1) {
        & > * {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }

    & > *:nth-child(2) {
        & > * {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }
}
