.dixu-avatar {
    border-radius: 50%;
    user-select: none;
    overflow: hidden;
    max-width: 100%;

    &--frame {
        @include themify {
            background: themed('color', 2, 3);
            box-shadow: themed('shadow', 9);
        }

        padding: 4px;
    }

    &__initials-wrapper {
        @include themify {
            background: themed('color', 2, 3);
        }

        position: relative;
        padding-top: 100%;
        border-radius: 50%;
        max-width: 100%;
    }

    &__initials {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
