.dixu-dropdown {
}

.dixu-dropdown-overlay {
    .ant-dropdown-menu {
        @include themify {
            border-radius: themed('border', 'radius', 'md');
            box-shadow: themed('shadow', 1);
        }

        overflow: hidden;
    }

    .ant-dropdown-menu-item {
        @include themify {
            font: themed('font', 'quicksand', 'desktop', 2);
            font-weight: 500;
            color: themed('color', 2, 8);
        }

        &-active {
            @include themify {
                background: themed('color', 2, 3);
            }

            & * {
                @include themify {
                    color: themed('color', 2, 8);
                }
            }
        }
    }
}
