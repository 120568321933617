.dixu-device-frame {
    @include themify {
        filter: drop-shadow(themed('shadow', 3));
    }

    position: relative;

    svg {
        display: block;
        width: 100%;
    }

    &__image {
        position: absolute;
        object-fit: cover;
        object-position: center;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
    }

    &--device-iphone-11-pro &__image {
        top: 2%;
        left: 6%;
        width: 88%;
        height: 96%;
    }
}
