.dixu-input-number {
    @include themify {
        background: themed('color', 2, 2);
        border-radius: themed('border', 'radius', 'md');
        border: 1px solid themed('color', 2, 4);
        color: themed('color', 2, 8);
    }

    @include border-radius-overflow-fix;

    max-width: 100%;
    overflow: hidden;

    @at-root .dixu-form--color-scheme-reverse & {
        @include themify {
            background: themed('color', 2, 1);
        }
    }

    .anticon {
        @include themify {
            color: themed('color', 2, 7);
        }
    }

    .ant-input-number-handler:hover .ant-input-number-handler-up-inner {
        @include themify {
            color: themed('color', 2, 7);
        }
    }

    .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
        @include themify {
            color: themed('color', 2, 7);
        }
    }

    .ant-input-number-handler-wrap {
        @include themify {
            border-left: 1px solid themed('color', 1, 5);
        }
    }

    .ant-input-number-handler-down {
        @include themify {
            border-top: 1px solid themed('color', 1, 5);
        }
    }

    &:hover {
        @include themify {
            border: 1px solid themed('color', 1, 5);
        }
    }

    &:focus {
        @include themify {
            border: 1px solid themed('color', 1, 5);
            box-shadow: themed('shadow', 5);
        }
    }

    &.ant-input-number-focused {
        @include themify {
            border: 1px solid themed('color', 1, 5);
            box-shadow: themed('shadow', 5);
        }
    }

    &.ant-input-number-sm {
        @include themify {
            border-radius: themed('border', 'radius', 'sm');
        }
    }

    &.ant-input-number-lg {
        @include themify {
            border-radius: themed('border', 'radius', 'lg');
        }
    }

    &--hide-handlers {
        .ant-input-number-handler-wrap {
            display: none;
        }
    }

    &--align-right input {
        text-align: right;
    }
}
