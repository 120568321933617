.dixu-realtor-experience-card {
    $avatar-offset: 75px;

    @include themify {
        border-radius: themed('border', 'radius', 'lg');
    }

    margin-top: $avatar-offset;
    padding: 0 56px 43px;

    &--full-height {
        height: calc(100% - #{$avatar-offset});
    }

    &--wide {
        padding: 0 32px 32px;
    }

    &__avatar-wrapper {
        @include themify {
            border: 3px solid themed('color', 2, 1);
            background: themed('color', 2, 2);
        }

        margin-top: -$avatar-offset;
        border-radius: 50%;
        filter: grayscale(1);
    }
}
