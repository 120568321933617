.dixu-listing-publish-controls {
    position: fixed;
    right: 32px;
    bottom: 32px;
    pointer-events: none;
    z-index: $z-index-high;

    &__buttons {
        display: flex;
        flex-direction: column;
    }

    &__button {
        @include themify {
            background: themed('color', 9, 6);
        }

        pointer-events: auto;

        &:hover {
            @include themify {
                background: themed('color', 9, 5);
            }
        }

        &:focus:not(:active) {
            @include themify {
                background: themed('color', 9, 5);
            }
        }

        &:active {
            @include themify {
                background: themed('color', 9, 7);
            }
        }

        &:not(:last-child) {
            margin-bottom: 12px;
        }

        &--secondary {
            @include themify {
                background: themed('color', 2, 7);
            }

            &:hover {
                @include themify {
                    background: themed('color', 2, 6);
                }
            }

            &:focus:not(:active) {
                @include themify {
                    background: themed('color', 2, 6);
                }
            }

            &:active {
                @include themify {
                    background: themed('color', 2, 8);
                }
            }
        }
    }
}
