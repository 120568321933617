.dixu-front-page-screen {
    text-align: center;

    @include bp-desktop {
        text-align: left;
    }

    & > section {
        &:nth-of-type(odd) {
            @include themify {
                background: themed('color', 2, 9);
            }
        }

        &:nth-of-type(even) {
            @include themify {
                background: themed('color', 2, 10);
            }
        }
    }
}
