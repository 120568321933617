.dixu-tooltip {
    outline: 0;
}

.dixu-tooltip-overlay {

    &.ant-tooltip-placement-top {
        .ant-tooltip-arrow {
            bottom: 2px !important;
        }
    }

    .ant-tooltip-arrow:before {
        @include themify {
            background: themed('color', 2, 8);
        }
    }


    .ant-tooltip-arrow-content {
        @include themify {
            background: themed('color', 2, 8);
        }
    }

    .ant-tooltip-inner {
        @include themify {
            background: themed('color', 2, 8);
            box-shadow: themed('shadow', 1);
        }
    }
}

.ant-slider-tooltip {
    @extend .dixu-tooltip-overlay;
}

.dixu-labelled-tooltip {
    &--titled {
        cursor: pointer;
    }

    &__icon {
        @include important {
            line-height: 1;
        }
    }
}
