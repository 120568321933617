@keyframes dixu-step-flow-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.dixu-step-flow {
    &__step {
        @include themify {
            animation: dixu-step-flow-fade-in themed('transition', 'speed', 'md') forwards;
        }
    }
}

.dixu-step-flow-info-wrapper {
    @include themify {
        background: themed('color', 2, 2);
    }

    position: relative;
    padding-top: 64px;
    height: var(--dixu-app-viewport-height);
    overflow: hidden;

    @include bp-desktop {
        display: flex;
        padding-top: 0;
    }

    &__info-container {
        @include themify {
            transition: height themed('transition', 'speed', 'md');
        }

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 64px;
        background: linear-gradient(0deg, rgba(81, 79, 112, 0.9), rgba(81, 79, 112, 0.9)),
            url('/images/street.jpeg') no-repeat center;
        background-size: cover;
        overflow: hidden;
        z-index: 1;
        scroll-behavior: smooth;

        &--open {
            height: 100%;
            overflow: auto;
        }

        @include bp-desktop {
            position: static;
            flex: 0 0 46.25%;
            height: auto;
            overflow: auto;
        }
    }

    &__info-header {
        @include themify {
            transition: height themed('transition', 'speed', 'md');
        }

        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;

        &--open {
            height: 128px;

            @include bp-tablet {
                height: 64px;
            }
        }

        @include bp-desktop {
            height: 128px;
            justify-content: flex-start;
            padding-left: 64px;
        }

        & > * {
            display: inline-flex;
        }

        svg {
            @include themify {
                fill: themed('color', 2, 1);
            }

            height: 32px;

            @include bp-desktop {
                height: 48px;
            }
        }
    }

    &__info-content {
        padding: 0 16px;
        display: flex;
        align-items: center;
        height: calc(100% - 256px);

        & > * {
            margin: auto 0;
            padding: 64px 0;
            width: 100%;
        }

        @include bp-tablet {
            padding: 0 32px;
            height: calc(100% - 128px);
        }

        @include bp-desktop {
            padding: 0 64px;
            display: flex;
            align-items: center;
            height: calc(100% - 256px);

            & > * {
                padding: 32px 0;
            }
        }
    }

    &__steps-container {
        position: relative;
        height: 100%;
        overflow: auto;
        padding: 16px 16px 72px;
        display: flex;
        align-items: center;
        scroll-behavior: smooth;

        @include bp-desktop {
            padding: 32px;
            flex: 1 1 auto;
        }

        &--with-header {
            padding: 16px;

            @include bp-desktop {
                padding: 32px;
            }
        }
    }

    &__steps-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 128px;
        overflow: hidden;
    }

    &__steps-content {
        margin: auto 0;
        padding: 16px 0;
        width: 100%;

        @include bp-desktop {
            padding: 32px 0;
        }
    }

    &__steps-header + &__steps-content {
        padding: 96px 0;
    }

    &__info-toggle-wrapper {
        @include themify {
            border: 2px solid themed('color', 2, 2);
            mtransition: border themed('transition', 'speed', 'md');
        }

        position: absolute;
        right: 16px;
        bottom: 16px;
        z-index: 1;
        border-radius: 50%;

        @include bp-desktop {
            display: none;
        }

        &--open {
            @include important {
                border: 2px solid transparent;
            }
        }
    }

    &__info-toggle {
        @include themify {
            background: themed('color', 2, 8);
            color: themed('color', 2, 1);
        }

        &--close {
            @include themify {
                background: themed('color', 2, 3);
                color: themed('color', 2, 8);
            }
        }
    }
}
