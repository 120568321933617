$dixu-sidebar-expanded-x-padding: 24px;
$dixu-sidebar-collapsed-x-padding: 16px;
$dixu-sidebar-y-padding: 16px;

.dixu-sidebar {
    display: flex;
    flex-direction: column;

    @include bp-desktop {
        flex-direction: row;
    }

    &__bar {
        @include themify {
            transition: width themed('transition', 'speed', 'md');
        }

        @include bp-mobile-and-tablet-all-only {
            z-index: $z-index-high;
            width: 100% !important;
        }

        @include enforce-hardware-acceleration;

        flex: 0 0 auto;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $dixu-sidebar-y-padding 0;

        @include bp-desktop {
            overflow: hidden;
        }

        &--sticky {
            position: sticky;
            top: 0;

            @include bp-desktop {
                height: var(--dixu-app-viewport-height);
            }
        }
    }

    &__bar-title-wrapper {
        @include themify {
            transition: padding themed('transition', 'speed', 'md');
        }

        @include bp-mobile-and-tablet-all-only {
            @include vhidden;
        }

        padding: 0 $dixu-sidebar-expanded-x-padding;
    }

    &--collapsed &__bar-title-wrapper {
        padding: 0 $dixu-sidebar-collapsed-x-padding;
    }

    &__bar-content {
        @include themify {
            transition: padding themed('transition', 'speed', 'md');
        }

        display: flex;
        flex: 1 1 auto;
        position: relative;
        padding: 0 $dixu-sidebar-expanded-x-padding;

        @include bp-desktop {
            margin-bottom: $dixu-sidebar-y-padding;
            overflow-x: hidden;
            overflow-y: auto;
        }

        & > * {
            @include bp-desktop {
                max-width: 100%;
            }
        }
    }

    &--collapsed &__bar-content {
        padding: 0 $dixu-sidebar-collapsed-x-padding;
    }

    &__bar-footer {
        @include themify {
            transition: padding themed('transition', 'speed', 'md');
        }
        display: none;
        padding: 0 $dixu-sidebar-expanded-x-padding;

        @include bp-desktop {
            display: block;
        }
    }

    &--collapsed &__bar-footer {
        padding: 0 $dixu-sidebar-collapsed-x-padding;
    }

    &__collapse-toggle {
        @include button-reset;

        display: flex;
        align-items: center;

        .dixu-text {
            @include themify {
                transition: color themed('transition', 'speed', 'md');
            }
        }

        &:hover,
        &:focus {
            .dixu-text {
                @include themify {
                    color: themed('color', 1, 5);
                }
            }
        }
    }

    &__children {
        @include themify {
            transition: padding-right themed('transition', 'speed', 'md');
        }

        flex: 1 1 auto;
        position: relative;

        @include bp-desktop {
            width: 1px; // Fix long text content in children causing overflow.
        }

        @include bp($max: map-get($breakpoints, 'desktop')) {
            padding-right: 0 !important;
        }
    }

    &--collapsed &__children {
        @include bp(
            $min: map-get($breakpoints, 'desktop'),
            $max: map-get($breakpoints, 'desktop-md')
        ) {
            padding-right: 0 !important;
        }
    }

    &:not(#{&}--collapsed) &__children {
        @include bp(
            $min: map-get($breakpoints, 'desktop'),
            $max: map-get($breakpoints, 'desktop-lg')
        ) {
            padding-right: 0 !important;
        }
    }

    &__children-content {
        @include themify {
            padding: $dixu-sidebar-y-padding 16px;
            height: 100%;
        }

        @include bp-desktop {
            @include themify {
                padding: $dixu-sidebar-y-padding themed('border', 'radius', 'xxl');
                margin-top: -(2 * themed('border', 'radius', 'xxl'));
                margin-bottom: -(2 * themed('border', 'radius', 'xxl'));
            }
        }
    }

    &__children-wrapper {
        position: relative;
    }

    &__children-frame {
        @include themify {
            width: themed('border', 'radius', 'xxl');
            height: themed('border', 'radius', 'xxl');
        }

        pointer-events: none;
        position: sticky;
        display: none;
        z-index: -1;

        @include bp-desktop {
            display: block;
        }

        &--top {
            @include themify {
                margin-bottom: themed('border', 'radius', 'xxl');
            }

            top: 0;
        }

        &--bottom {
            @include themify {
                margin-top: themed('border', 'radius', 'xxl');
            }

            bottom: 0;
        }

        &--hidden {
            visibility: hidden;
        }
    }

    &__children-frame-background {
        @include themify {
            height: themed('border', 'radius', 'xxl');
        }

        &--top {
            @include themify {
                border-top-left-radius: themed('border', 'radius', 'xxl');
            }

            top: 0;
        }

        &--bottom {
            @include themify {
                border-bottom-left-radius: themed('border', 'radius', 'xxl');
            }

            bottom: 0;
        }
    }
}

.dixu-sidebar-content {
    &--transition-fade {
        @include themify {
            transition: all themed('transition', 'speed', 'md');
            transition-property: transform, max-width, max-height, opacity;
        }

        transform-origin: left center;
        transform: scale(1);

        @at-root .dixu-sidebar--collapsed .dixu-sidebar__bar & {
            @include bp-desktop {
                opacity: 0;
                transform: scale(0);
                max-width: 0 !important;
                max-height: 0 !important;
            }
        }
    }

    &--transition-center {
        @include themify {
            transition: transform themed('transition', 'speed', 'md');
        }

        transform: translateX(0%);

        @at-root .dixu-sidebar--collapsed .dixu-sidebar__bar & {
            @include bp-desktop {
                transform: translateX(25%);
            }
        }
    }
}

.dixu-sidebar-divider {
    @include themify {
        transition: all themed('transition', 'speed', 'md');
        transition-property: left, width;
    }

    display: none !important;
    position: relative;
    left: -$dixu-sidebar-expanded-x-padding;
    width: calc(100% + #{$dixu-sidebar-expanded-x-padding * 2}) !important;

    @at-root .dixu-sidebar--collapsed & {
        left: -$dixu-sidebar-collapsed-x-padding;
        width: calc(100% + 32px) !important;
    }

    @include bp-desktop {
        display: block !important;
    }
}
