.dixu-link {
    color: inherit;
    text-decoration: none;

    &--active,
    &:hover,
    &:focus,
    &:active {
        @include themify {
            color: themed('color', 1, 5);
        }
    }
}
