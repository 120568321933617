.dixu-expandable-tag {
    position: relative;
    display: inline-block;
    outline: 0;
    cursor: pointer;
    z-index: $z-index-low;

    &--expanded {
        z-index: $z-index-medium;
    }

    &__content {
        @include themify {
            border-radius: themed('border', 'radius', 'md');
            transition: height themed('transition', 'speed', 'md');
        }

        @include important {
            display: flex;
            line-height: 1;
            white-space: nowrap;
            width: 100%;
        }

        align-items: center;
        flex-direction: column;
        overflow: hidden;
        padding: 8px 24px;
        position: absolute;
        user-select: none;
        border-width: 1px;
        border-style: solid;
    }
}
