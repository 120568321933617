.dixu-popover {
    .ant-popover-inner {
        @include themify {
            border-radius: themed('border', 'radius', 'sm');
            box-shadow: themed('shadow', 1)
        }

        .ant-popover-title {
            font-weight: bold;
            font-size: 14px;
            @include themify {
                color: themed('color', 2, 9);
            }
        }
    }
}
