@mixin dixu-card-collapsible {
    &__title-wrapper {
        cursor: pointer;

        .anticon-right {
            display: inline-block;
        }
    }
}

@mixin dixu-card-collapse-forbidden {
    &__title-wrapper {
        pointer-events: none;
    }

    &__content-wrapper--collapsible {
        display: block !important;
        height: auto !important;
    }
}

.dixu-card {
    &--default {
        height: 100%;
        overflow: hidden;

        & & {
            box-shadow: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &--default &__title-wrapper {
        outline: 0;

        .anticon-right {
            svg {
                @include themify {
                    transition: transform themed('transition', 'speed', 'md');
                }
            }
        }
    }

    &__title-wrapper {
        .anticon-right {
            display: none;
        }
    }

    &--collapsible-mobile & {
        @include bp-mobile-all-only {
            @include dixu-card-collapsible;
        }
    }

    &--collapsible-mobile:not(#{&}--collapsible-tablet) & {
        @include bp-tablet-all-only {
            @include dixu-card-collapse-forbidden;
        }
    }

    &--collapsible-mobile:not(#{&}--collapsible-desktop) & {
        @include bp-desktop-all-only {
            @include dixu-card-collapse-forbidden;
        }
    }

    &--collapsible-tablet & {
        @include bp-tablet-all-only {
            @include dixu-card-collapsible;
        }
    }

    &--collapsible-tablet:not(#{&}--collapsible-mobile) & {
        @include bp-mobile-all-only {
            @include dixu-card-collapse-forbidden;
        }
    }

    &--collapsible-tablet:not(#{&}--collapsible-desktop) & {
        @include bp-desktop-all-only {
            @include dixu-card-collapse-forbidden;
        }
    }

    &--collapsible-desktop & {
        @include bp-desktop-all-only {
            @include dixu-card-collapsible;
        }
    }

    &--collapsible-desktop:not(#{&}--collapsible-mobile) & {
        @include bp-mobile-all-only {
            @include dixu-card-collapse-forbidden;
        }
    }

    &--collapsible-tablet:not(#{&}--collapsible-tablet) & {
        @include bp-tablet-all-only {
            @include dixu-card-collapse-forbidden;
        }
    }

    &--collapsible-true & {
        @include dixu-card-collapsible;
    }

    &--bordered {
        display: flex;
    }

    &--bordered &__content {
        flex: 1 1 auto;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
