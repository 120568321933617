.dixu-front-page-hero {
    position: relative;
    background: linear-gradient(
            180deg,
            rgba(81, 79, 112, 0.8) 0%,
            rgba(81, 79, 112, 0.9) 50%,
            #2f2e41 100%
        ),
        url('/images/buildings_1_mobile.jpg') no-repeat center top;
    background-size: cover;
    padding-top: 64px !important;

    @include bp-mobile-all-only {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    @include bp-tablet {
        padding-top: 90px !important;
        padding-bottom: 90px !important;
    }

    @include bp-desktop {
        background: linear-gradient(
                180deg,
                rgba(81, 79, 112, 0.8) 0%,
                rgba(81, 79, 112, 0.9) 50%,
                #2f2e41 100%
            ),
            url('/images/buildings_1.jpg') no-repeat center top;
        background-size: cover;
    }

    &__content {
        padding-top: 70px !important;

        @include bp-tablet {
            padding-top: 127px;
        }

        @include bp-desktop {
            padding-top: 0 !important;
        }
    }

    &__main-content {
        &--with-listings {
            min-height: 50vh;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }

    &__cta {
        @include themify {
            color: themed('color', 2, 1) !important;
            border-color: themed('color', 2, 1) !important;
        }
    }
}
