.dixu-reload-overlay {
    @include themify {
        background: rgba(themed('color', 2, 8), 0.8);
        transition: opacity themed('transition', 'speed', 'md');
    }

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $z-index-high;
    opacity: 0;
    pointer-events: none;

    &--reloading {
        opacity: 1;
        pointer-events: auto;
    }
}
