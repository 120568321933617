/**
 * The clip-path cuts are used twice to prevent small edge artifacts on some
 * browsers.
 */

@mixin dixu-ribbon-cut {
    clip-path: polygon(
        0 0,
        100% 0,
        100% calc(100% - 16px),
        calc(100% - 8px) calc(100% - 8px),
        calc(100% - 8px) calc(100% - 16px),
        8px calc(100% - 16px),
        8px calc(100% - 8px),
        0 calc(100% - 16px)
    );
}

@mixin dixu-ribbon-right-30-cut {
    clip-path: polygon(
        55px 0,
        calc(100% - 18px) 0,
        100% calc(100% - 16px),
        100% 100%,
        calc(100% - 9px) calc(100% - 16px),
        14px calc(100% - 16px),
        0 calc(100% - 8px),
        0 calc(100% - 16px)
    );
}

@mixin dixu-ribbon-left-30-cut {
    clip-path: polygon(
        18px 0,
        calc(100% - 55px) 0,
        100% calc(100% - 16px),
        100% calc(100% - 8px),
        calc(100% - 14px) calc(100% - 16px),
        9px calc(100% - 16px),
        0 100%,
        0 calc(100% - 16px)
    );
}

.dixu-ribbon {
    @include dixu-ribbon-cut;

    display: flex;
    position: relative;
    user-select: none;
    pointer-events: none;
    margin-bottom: -8px;

    &--position {
        &-top-right,
        &-top-left {
            position: absolute;
        }

        &-top-right {
            top: 0;
            right: 0;
        }

        &-top-left {
            top: 0;
            left: 0;
        }
    }

    &__content {
        @include dixu-ribbon-cut;

        flex: 1 1 auto;
        padding: 5px 16px;
        border-bottom: 16px solid;

        @include themify {
            background: themed('color', 1, 6);
            border-color: themed('color', 1, 7);
        }
    }

    &--position-top-right#{&}--angle-30 {
        @include dixu-ribbon-right-30-cut;

        transform: rotate(30deg);
    }

    &--position-top-left#{&}--angle-30 {
        @include dixu-ribbon-left-30-cut;

        transform: rotate(-30deg);
    }

    &--position-top-right#{&}--angle-30 &__content {
        @include dixu-ribbon-right-30-cut;

        padding: 5px 64px;

        & > * {
            position: relative;
            top: -2px;
        }
    }

    &--position-top-left#{&}--angle-30 &__content {
        @include dixu-ribbon-left-30-cut;

        padding: 5px 64px 5px 40px;

        & > * {
            position: relative;
            top: -2px;
        }
    }
}
