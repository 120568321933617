.dixu-table {
    table,
    .ant-table,
    .ant-table-row,
    .ant-table-expanded-row,
    .ant-table-cell,
    .ant-table-placeholder {
        background: transparent;
    }

    .ant-table-measure-row {
        & * {
            font-size: 0;
            line-height: 0;
        }
    }

    .ant-pagination {
        margin-top: 8px;
        display: flex;
        justify-content: flex-end;
    }

    &--type-default {
        .ant-table-placeholder {
            &:hover {
                .ant-table-cell {
                    background: transparent;
                }
            }
        }

        .ant-table-cell {
            vertical-align: middle;
        }

        .ant-table-expanded-row {
            .ant-table {
                margin: 0 !important;
            }

            .ant-table-cell {
                @include themify {
                    font: themed('font', 'quicksand', 'desktop', 2);
                    color: themed('color', 2, 8);
                }

                border-bottom: 0;
                padding: 0;

                &:first-child {
                    @include themify {
                        @include important {
                            border-top-left-radius: themed('border', 'radius', 'sm');
                            border-bottom-left-radius: themed('border', 'radius', 'sm');
                        }
                    }
                }

                &:last-child {
                    @include themify {
                        @include important {
                            border-top-right-radius: themed('border', 'radius', 'sm');
                            border-bottom-right-radius: themed('border', 'radius', 'sm');
                        }
                    }
                }
            }

            &:not(:last-child) > .ant-table-cell > :last-child {
                @include themify {
                    border-bottom: 1px solid themed('color', 2, 4);
                }
            }

            &:hover {
                & > .ant-table-cell {
                    @include themify {
                        background: themed('color', 2, 3);
                    }
                }
            }
        }

        .ant-table-row {
            .ant-table-cell {
                @include themify {
                    font: themed('font', 'quicksand', 'desktop', 2);
                    color: themed('color', 2, 8);
                }

                border-bottom: 0;
                padding: 11px 16px;

                &:first-child {
                    @include themify {
                        border-top-left-radius: themed('border', 'radius', 'sm');
                        border-bottom-left-radius: themed('border', 'radius', 'sm');
                    }
                }

                &:last-child {
                    @include themify {
                        border-top-right-radius: themed('border', 'radius', 'sm');
                        border-bottom-right-radius: themed('border', 'radius', 'sm');
                    }
                }
            }

            &:hover {
                & > .ant-table-cell {
                    @include themify {
                        background: themed('color', 2, 3);
                    }
                }
            }
        }

        .ant-table-thead {
            .ant-table-cell {
                @include themify {
                    font: bold themed('font', 'quicksand', 'desktop', 2);
                    color: themed('color', 2, 9);
                }

                border-bottom: 0;
            }
        }
    }

    &--type-plain {
        .ant-table-thead {
            .ant-table-cell {
                @include themify {
                    font: bold themed('font', 'quicksand', 'desktop', 2);
                    color: themed('color', 2, 9);
                }

                @include important {
                    background: transparent;
                    padding: 0 16px 8px 0;
                }

                border-bottom: 0;
                vertical-align: middle;
            }
        }

        .ant-table-tbody {
            .ant-table-cell {
                @include themify {
                    font: themed('font', 'quicksand', 'desktop', 2);
                    color: themed('color', 2, 8);
                }

                @include important {
                    padding: 4px 32px 4px 0;
                }

                border-bottom: 0;
            }
        }
    }
}
