.dixu-vimeo-video {
    @include themify {
        background: themed('color', 2, 9);
    }

    position: relative;
    overflow: hidden;

    &__thumbnail {
        @include button-reset;

        @include themify {
            color: rgba(themed('color', 2, 1), 0.9);
            transition: color themed('transition', 'speed', 'md');
            text-shadow: themed('shadow', 2);
        }

        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        z-index: 1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        font-size: #{'min(25vw, 96px)'};
        text-align: center;

        &:hover,
        &:focus {
            @include themify {
                color: rgba(themed('color', 2, 1), 1);
            }
        }

        svg {
            @include themify {
                filter: drop-shadow(themed('shadow', 2));
            }
        }
    }

    &__player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
