.dixu-seller-card {
    text-align: center;

    @include bp-desktop {
        text-align: left;
    }

    &__basic-info {
        @include bp-desktop {
            flex-wrap: nowrap;
        }
    }

    &__avatar-wrapper {
        @include bp-mobile-and-tablet-all-only {
            flex: 0 0 100%;
        }
    }

    &__additional-info {
        justify-content: center;

        @include bp-desktop {
            justify-content: flex-end;
        }
    }

    &__logos {
        @include bp-desktop {
            flex-wrap: nowrap;
        }
    }

    &__company-logo {
        img {
            @include bp-desktop {
                object-position: right center;
            }
        }
    }

    &__actions {
        @include bp-mobile-and-tablet-all-only {
            flex: 0 0 100%;
        }
    }
}
