/**
 * Utility classes defined in this file are imported before component and screen
 * specific styles so these can be overridden when needed.
 */

/**
 * Themified utility classes.
 */

@include theme-loop(
    $selectorPrefix: '.color',
    $styleName: 'color',
    $path: (
        'color',
    )
);

@include theme-loop(
    $selectorPrefix: '.fill',
    $styleName: 'fill',
    $path: (
        'color',
    )
);

@include theme-loop(
    $selectorPrefix: '.bg',
    $styleName: 'background',
    $path: (
        'color',
    )
);

@include theme-loop(
    $selectorPrefix: '.box-shadow',
    $styleName: 'box-shadow',
    $path: (
        'shadow',
    )
);

@include theme-loop(
    $selectorPrefix: '.text-shadow',
    $styleName: 'text-shadow',
    $path: (
        'shadow',
    )
);

@include theme-loop(
    $selectorPrefix: '.drop-shadow',
    $styleName: 'filter',
    $valueTemplate: 'drop-shadow(%VALUE%)',
    $path: (
        'shadow',
    )
);

@include theme-loop(
    $selectorPrefix: '.border',
    $styleName: 'border',
    $valueTemplate: '%VALUE% solid',
    $path: (
        'border',
        'width',
    )
);

@include theme-loop(
    $selectorPrefix: '.border-color',
    $styleName: 'border-color',
    $path: (
        'color',
    )
);

@include theme-loop(
    $selectorPrefix: '.border-radius',
    $styleName: 'border-radius',
    $path: (
        'border',
        'radius',
    )
) {
    @include border-radius-overflow-fix;
    overflow: hidden;
}

@include theme-loop(
    $selectorPrefix: '.font-quicksand',
    $styleName: 'font',
    $path: (
        'font',
        'quicksand',
        'desktop',
    )
);

@include theme-loop(
    $selectorPrefix: '.responsive-font-quicksand',
    $styleName: 'font',
    $path: (
        'font',
        'quicksand',
        'mobile',
    )
);

@include bp-tablet {
    @include theme-loop(
        $selectorPrefix: '.responsive-font-quicksand',
        $styleName: 'font',
        $path: (
            'font',
            'quicksand',
            'tablet',
        )
    );
}

@include bp-desktop {
    @include theme-loop(
        $selectorPrefix: '.responsive-font-quicksand',
        $styleName: 'font',
        $path: (
            'font',
            'quicksand',
            'desktop',
        )
    );
}

@include theme-loop(
    $selectorPrefix: '.font-ropa',
    $styleName: 'font',
    $path: (
        'font',
        'ropa',
        'desktop',
    )
);

@include theme-loop(
    $selectorPrefix: '.responsive-font-ropa',
    $styleName: 'font',
    $path: (
        'font',
        'ropa',
        'mobile',
    )
);

@include bp-tablet {
    @include theme-loop(
        $selectorPrefix: '.responsive-font-ropa',
        $styleName: 'font',
        $path: (
            'font',
            'ropa',
            'tablet',
        )
    );
}

@include bp-desktop {
    @include theme-loop(
        $selectorPrefix: '.responsive-font-ropa',
        $styleName: 'font',
        $path: (
            'font',
            'ropa',
            'desktop',
        )
    );
}

@include theme-loop(
    $selectorPrefix: '.transition',
    $styleName: 'transition',
    $valueTemplate: 'all %VALUE%',
    $path: (
        'transition',
        'speed',
    )
);

/**
 * Misc.
 */

.button-reset {
    @include button-reset;
}

.block {
    display: block;
    width: 100%;
}

.inline-block {
    display: inline-block;
}

.subtle-hover-scale-up {
    transform-origin: center;

    &:hover,
    &:focus {
        transform: scale(1.01);
    }
}
