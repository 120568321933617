.dixu-container {
    display: flex;
    flex: 1 1 auto;

    &--no-overflow {
        overflow: hidden;
    }

    &--horizontal-padding-sm {
        padding: {
            left: 8px;
            right: 8px;
        }
    }

    &--dynamic-padding#{&}--horizontal-padding-sm {
        @include bp-mobile-and-tablet-all-only {
            padding: {
                left: 4px;
                right: 4px;
            }
        }
    }

    &--horizontal-padding-md {
        padding: {
            left: 16px;
            right: 16px;
        }
    }

    &--dynamic-padding#{&}--horizontal-padding-md {
        @include bp-mobile-and-tablet-all-only {
            padding: {
                left: 8px;
                right: 8px;
            }
        }
    }

    &--horizontal-padding-lg {
        padding: {
            left: 24px;
            right: 24px;
        }
    }

    &--dynamic-padding#{&}--horizontal-padding-lg {
        @include bp-mobile-and-tablet-all-only {
            padding: {
                left: 16px;
                right: 16px;
            }
        }
    }

    &--horizontal-padding-xl {
        padding: {
            left: 32px;
            right: 32px;
        }
    }

    &--dynamic-padding#{&}--horizontal-padding-xl {
        @include bp-mobile-and-tablet-all-only {
            padding: {
                left: 24px;
                right: 24px;
            }
        }
    }

    &--horizontal-padding-xxl {
        padding: {
            left: 48px;
            right: 48px;
        }
    }

    &--dynamic-padding#{&}--horizontal-padding-xxl {
        @include bp-mobile-and-tablet-all-only {
            padding: {
                left: 32px;
                right: 32px;
            }
        }
    }

    &--horizontal-padding-xxxl {
        padding: {
            left: 64px;
            right: 64px;
        }
    }

    &--dynamic-padding#{&}--horizontal-padding-xxxl {
        @include bp-mobile-and-tablet-all-only {
            padding: {
                left: 48px;
                right: 48px;
            }
        }
    }

    &--vertical-padding-sm {
        padding: {
            top: 8px;
            bottom: 8px;
        }
    }

    &--dynamic-padding#{&}--vertical-padding-sm {
        @include bp-mobile-and-tablet-all-only {
            padding: {
                top: 4px;
                bottom: 4px;
            }
        }
    }

    &--vertical-padding-md {
        padding: {
            top: 16px;
            bottom: 16px;
        }
    }

    &--dynamic-padding#{&}--vertical-padding-md {
        @include bp-mobile-and-tablet-all-only {
            padding: {
                top: 8px;
                bottom: 8px;
            }
        }
    }

    &--vertical-padding-lg {
        padding: {
            top: 24px;
            bottom: 24px;
        }
    }

    &--dynamic-padding#{&}--vertical-padding-lg {
        @include bp-mobile-and-tablet-all-only {
            padding: {
                top: 16px;
                bottom: 16px;
            }
        }
    }

    &--vertical-padding-xl {
        padding: {
            top: 32px;
            bottom: 32px;
        }
    }

    &--dynamic-padding#{&}--vertical-padding-xl {
        @include bp-mobile-and-tablet-all-only {
            padding: {
                top: 24px;
                bottom: 24px;
            }
        }
    }

    &--vertical-padding-xxl {
        padding: {
            top: 48px;
            bottom: 48px;
        }
    }

    &--dynamic-padding#{&}--vertical-padding-xxl {
        @include bp-mobile-and-tablet-all-only {
            padding: {
                top: 32px;
                bottom: 32px;
            }
        }
    }

    &--vertical-padding-xxxl {
        padding: {
            top: 64px;
            bottom: 64px;
        }
    }

    &--dynamic-padding#{&}--vertical-padding-xxxl {
        @include bp-mobile-and-tablet-all-only {
            padding: {
                top: 48px;
                bottom: 48px;
            }
        }
    }

    & > &__content {
        max-width: 100%;
    }

    &--max-width-sm > &__content {
        max-width: #{'min(576px, 100%, 100vw)'};
    }

    &--max-width-md > &__content {
        max-width: #{'min(768px, 100%, 100vw)'};
    }

    &--max-width-lg > &__content {
        max-width: #{'min(992px, 100%, 100vw)'};
    }

    &--max-width-xl > &__content {
        max-width: #{'min(1200px, 100%, 100vw)'};
    }

    &--max-width-xxl > &__content {
        max-width: #{'min(1440px, 100%, 100vw)'};
    }

    &--center-horizontally {
        justify-content: center;
    }

    &--center-vertically {
        align-items: center;
    }

    &--height {
        &-full {
            min-height: 100%;
        }

        &-full-view {
            min-height: 100vh;
        }

        &-half-view {
            min-height: 50vh;
        }
    }

    &--height-full-view#{&}--consider-nav {
        min-height: calc(100vh - 64px);

        @include bp-desktop {
            min-height: calc(100vh - 80px);
        }
    }

    &__content {
        flex: 1 1 auto;
        position: relative;
    }
}
