.dixu-for-seller-screen {
    text-align: center;

    @include bp-desktop {
        text-align: left;
    }

    &__experiences-cta {
        @include themify {
            color: themed('color', 2, 1) !important;
            border-color: themed('color', 2, 1) !important;
        }
    }

    &__how-to-sell-video-wrapper {
        overflow: hidden;

        @include themify {
            border: 4px solid themed('color', 2, 10);
            border-radius: themed('border', 'radius', 'lg');
        }
    }
}
