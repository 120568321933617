.dixu-listing-card {
    @include themify {
        box-shadow: themed('shadow', 2);
        transition: all themed('transition', 'speed', 'md');
        transition-property: top, box-shadow, border;
    }

    position: relative;
    top: 0;
    max-width: 100%;

    &:focus {
        @include themify {
            box-shadow: 0 0 0 8px rgba(themed('color', 1, 5), 0.15);
        }
    }

    &:hover {
        @include bp-tablet {
            @include themify {
                box-shadow: themed('shadow', 3);
            }

            top: -8px;
        }
    }

    &:active {
        @include themify {
            box-shadow: themed('shadow', 2) inset;
        }
    }

    &__header-wrapper {
        display: flex;
        min-height: 72px;

        & > * {
            flex: 1 1 auto;
        }

        @include bp-tablet {
            min-height: 76px;
        }

        @include bp-desktop {
            min-height: 76px;
        }
    }

    &__content-container {
        position: relative;
        overflow: hidden;
        user-select: none;
    }

    &__draft {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 16px 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__additional-info {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 16px 24px;
    }

    &__footer-wrapper {
        display: flex;
        min-height: 72px;

        & > * {
            flex: 1 1 auto;
        }

        @include bp-tablet {
            min-height: 76px;
        }

        @include bp-desktop {
            min-height: 76px;
        }
    }
}
