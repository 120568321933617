.dixu-popconfirm {
    &.ant-popover-placement-top {
        .ant-popover-arrow {
            bottom: 2px !important;
        }
    }

    .ant-popconfirm-buttons {
        display: none;
    }

    .ant-popconfirm-message {
        margin-bottom: 0;
    }

    .ant-popover-inner {
        @include themify {
            border-radius: themed('border', 'radius', 'md');
            background: themed('color', 2, 1);
            box-shadow: themed('shadow', 1);
        }

        max-width: 240px;
    }

    .ant-popover-message {
        padding: 4px 0;
    }

    .ant-popover-buttons {
        display: none;
    }
}
