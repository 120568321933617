.dixu-purchase-offers-table {
    &__mobile-row {
        &:not(:last-child):not(#{&}--secondary) {
            & > div {
                @include themify {
                    border-bottom: 1px solid themed('color', 2, 4);
                }
            }
        }
    }
}
