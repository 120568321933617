.dixu-upload {
    max-width: 100%;

    @include themify {
        @include important {
            border-radius: themed('border', 'radius', 'lg');
        }
    }

    @include important {
        padding: 8px;
    }

    &:not(.ant-upload-disabled):hover {
        @include themify {
            @include important {
                border-color: themed('color', 1, 5);
            }
        }
    }

    &--dragger {
    }

    .ant-upload {
        @include important(2) {
            padding: 0;
        }
    }

    &--dragger &__icon {
        // Ant Design defines some styles specifically for the "plus" icon inside
        // the Dragger.
        .anticon-plus {
            @include themify {
                color: themed('color', 2, 7);
                font: themed('font', 'quicksand', 'desktop', 4);
            }
        }
    }

    &--dragger:not(.ant-upload-disabled):hover &__icon {
        .anticon-plus {
            @include themify {
                color: themed('color', 2, 7);
            }
        }
    }
}
