.dixu-progress {
    @include theme-loop(
        $selectorPrefix: '#{&}--stroke-color',
        $styleName: 'background',
        $selectorTemplate: '%PREFIX%-%KEY% .ant-progress-bg',
        $path: (
            'color',
        )
    );

    @include theme-loop(
        $selectorPrefix: '#{&}--trail-color',
        $styleName: 'background',
        $selectorTemplate: '%PREFIX%-%KEY% .ant-progress-inner',
        $path: (
            'color',
        )
    );

    .ant-progress-text {
        @include themify {
            font: themed('font', 'quicksand', 'desktop', 2);
            color: themed('color', 2, 8);
        }
    }
}
