.dixu-own-pages-listing-frame {
    &__main-tabs {
        @include bp-mobile-and-tablet-all-only {
            margin-left: -16px !important;
            margin-right: -16px !important;
            margin-bottom: -16px !important;
        }
    }

    &__main-card {
        @include bp-mobile-and-tablet-all-only {
            border-radius: 0 !important;
        }
    }
}
