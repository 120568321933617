/**
 * Themes.
 */

$themes: (
    default: (
        color: (
            1: // Dixu Blue
                (
                    1: #f4fafa,
                    2: #edfbfb,
                    3: #d3f4f4,
                    4: #9fe9e9,
                    5: #63dada,
                    6: #0eb0b0,
                    7: #0c7b7b,
                    8: #0a6262,
                    9: #074a4a,
                    10: #053131,
                ),
            2: // Dixu Gray
                (
                    1: #ffffff,
                    2: #f8f8fd,
                    3: #eeedf9,
                    4: #d5d4e8,
                    5: #bfbed8,
                    6: #75739c,
                    7: #626087,
                    8: #514f70,
                    9: #403f58,
                    10: #2f2e41,
                ),
            3: // Dust Red
                (
                    1: #fff1f0,
                    2: #ffccc7,
                    3: #ffa39e,
                    4: #ff7875,
                    5: #ff4d4f,
                    6: #f5222d,
                    7: #cf1322,
                    8: #a8071a,
                    9: #820014,
                    10: #5c0011,
                ),
            4: // Volcano
                (
                    1: #fff2e8,
                    2: #ffd8bf,
                    3: #ffbb96,
                    4: #ff9c6e,
                    5: #ff7a45,
                    6: #fa541c,
                    7: #d4380d,
                    8: #ad2102,
                    9: #871400,
                    10: #610b00,
                ),
            9: // Polar Green
                (
                    1: #f6ffed,
                    2: #d9f7be,
                    3: #b7eb8f,
                    4: #95de64,
                    5: #73d13d,
                    6: #52c41a,
                    7: #389e0d,
                    8: #237804,
                    9: #135200,
                    10: #092b00,
                ),
        ),
        shadow: (
            1: 0px 2px 8px rgba(0, 0, 0, 0.15),
            2: 0px 4px 12px rgba(0, 0, 0, 0.15),
            3: 0px 16px 32px rgba(0, 0, 0, 0.08),
            4: 0px 4px 16px rgba(0, 0, 0, 0.05),
            5: 0px 0px 4px rgba(19, 149, 149, 0.5),
            6: 0px 4px 4px rgba(0, 0, 0, 0.07),
            7: 0px -16px 32px rgba(0, 0, 0, 0.08),
            8: 0px 1px 3px #3f3d56,
            9: 0px -3px 16px rgba(117, 115, 156, 0.09) inset,
        ),
        border: (
            width: (
                xs: 1px,
                sm: 2px,
            ),
            radius: (
                xs: 4px,
                sm: 8px,
                md: 12px,
                lg: 16px,
                xl: 24px,
                xxl: 32px,
            ),
        ),
        transition: (
            speed: (
                md: 300ms,
                xl: 600ms,
            ),
        ),
        font: (
            quicksand: (
                mobile: (
                    1: #{'12px/20px Quicksand, sans-serif'},
                    2: #{'14px/22px Quicksand, sans-serif'},
                    3: #{'16px/24px Quicksand, sans-serif'},
                    4: #{'16px/24px Quicksand, sans-serif'},
                    5: #{'20px/28px Quicksand, sans-serif'},
                    6: #{'24px/32px Quicksand, sans-serif'},
                    7: #{'30px/38px Quicksand, sans-serif'},
                    8: #{'38px/46px Quicksand, sans-serif'},
                    9: #{'46px/54px Quicksand, sans-serif'},
                ),
                tablet: (
                    1: #{'12px/20px Quicksand, sans-serif'},
                    2: #{'14px/22px Quicksand, sans-serif'},
                    3: #{'16px/24px Quicksand, sans-serif'},
                    4: #{'20px/28px Quicksand, sans-serif'},
                    5: #{'24px/32px Quicksand, sans-serif'},
                    6: #{'30px/38px Quicksand, sans-serif'},
                    7: #{'38px/46px Quicksand, sans-serif'},
                    8: #{'46px/54px Quicksand, sans-serif'},
                    9: #{'56px/64px Quicksand, sans-serif'},
                ),
                desktop: (
                    1: #{'12px/20px Quicksand, sans-serif'},
                    2: #{'14px/22px Quicksand, sans-serif'},
                    3: #{'16px/24px Quicksand, sans-serif'},
                    4: #{'20px/28px Quicksand, sans-serif'},
                    5: #{'24px/32px Quicksand, sans-serif'},
                    6: #{'30px/38px Quicksand, sans-serif'},
                    7: #{'38px/46px Quicksand, sans-serif'},
                    8: #{'46px/54px Quicksand, sans-serif'},
                    9: #{'56px/64px Quicksand, sans-serif'},
                ),
            ),
            ropa: (
                mobile: (
                    1: #{'italic 12px/16px ropa-soft-pro, sans-serif'},
                    2: #{'italic 14px/18px ropa-soft-pro, sans-serif'},
                    3: #{'italic 16px/20px ropa-soft-pro, sans-serif'},
                    4: #{'italic 16px/20px ropa-soft-pro, sans-serif'},
                    5: #{'italic 20px/24px ropa-soft-pro, sans-serif'},
                    6: #{'italic 24px/28px ropa-soft-pro, sans-serif'},
                    7: #{'italic 30px/34px ropa-soft-pro, sans-serif'},
                    8: #{'italic 32px/36px ropa-soft-pro, sans-serif'},
                    9: #{'italic 46px/50px ropa-soft-pro, sans-serif'},
                ),
                tablet: (
                    1: #{'italic 12px/16px ropa-soft-pro, sans-serif'},
                    2: #{'italic 14px/18px ropa-soft-pro, sans-serif'},
                    3: #{'italic 16px/20px ropa-soft-pro, sans-serif'},
                    4: #{'italic 20px/24px ropa-soft-pro, sans-serif'},
                    5: #{'italic 24px/28px ropa-soft-pro, sans-serif'},
                    6: #{'italic 30px/34px ropa-soft-pro, sans-serif'},
                    7: #{'italic 48px/52px ropa-soft-pro, sans-serif'},
                    8: #{'italic 58px/62px ropa-soft-pro, sans-serif'},
                    9: #{'italic 64px/68px ropa-soft-pro, sans-serif'},
                ),
                desktop: (
                    1: #{'italic 12px/16px ropa-soft-pro, sans-serif'},
                    2: #{'italic 14px/18px ropa-soft-pro, sans-serif'},
                    3: #{'italic 16px/20px ropa-soft-pro, sans-serif'},
                    4: #{'italic 20px/24px ropa-soft-pro, sans-serif'},
                    5: #{'italic 24px/28px ropa-soft-pro, sans-serif'},
                    6: #{'italic 30px/34px ropa-soft-pro, sans-serif'},
                    7: #{'italic 48px/52px ropa-soft-pro, sans-serif'},
                    8: #{'italic 58px/62px ropa-soft-pro, sans-serif'},
                    9: #{'italic 64px/68px ropa-soft-pro, sans-serif'},
                ),
            ),
        ),
    ),
);

/**
 * Breakpoints.
 *
 * These need to be defined with em units because of a Safari bug.
 * https://danburzo.ro/media-query-units/#the-trouble-with-safari-again-:~:text=The%20trouble%20with%20Safari%20%28again%29
 */

$breakpoints: (
    'mobile-md': em(375),
    'mobile-lg': em(576),
    'tablet': em(768),
    'tablet-md': em(992),
    'desktop': em(1200),
    'desktop-md': em(1600),
    'desktop-lg': em(1920),
    'desktop-xl': em(2560),
    'desktop-xxl': em(3840),
) !default;

/**
 * Z-indexes.
 */

$z-index-low: 1;
$z-index-medium: 2;
$z-index-high: 3;
$z-index-top: 2147483647;

/**
 * Exports.
 */

:export {
    z-index: {
        low: $z-index-low;
        medium: $z-index-medium;
        high: $z-index-high;
        top: $z-index-top;
    }
    bp: {
        mobile-md: map-get($breakpoints, 'mobile-md');
        mobile-lg: map-get($breakpoints, 'mobile-lg');
        tablet: map-get($breakpoints, 'tablet');
        tablet-md: map-get($breakpoints, 'tablet-md');
        desktop: map-get($breakpoints, 'desktop');
        desktop-md: map-get($breakpoints, 'desktop-md');
        desktop-lg: map-get($breakpoints, 'desktop-lg');
        desktop-xl: map-get($breakpoints, 'desktop-xl');
        desktop-xxl: map-get($breakpoints, 'desktop-xxl');
    }
}
