.dixu-form {
    @include themify {
        font: themed('font', 'quicksand', 'desktop', 3);
        color: themed('color', 2, 8);
    }
}

.dixu-form-item {
    @include important {
        margin-bottom: 8px;
    }

    @at-root :not(#{&}) + & {
        margin-top: 16px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .ant-form-item-label {
        @include important(1, '.ant-form-item-label') {
            padding-bottom: 2px;

            label {
                height: auto;
            }
        }

        label {
            cursor: pointer;
            white-space: pre-line;
            user-select: none;

            @include themify {
                color: themed('color', 2, 9);
                font: themed('font', 'quicksand', 'desktop', 2);
                font-weight: 500;
                line-height: 1;
            }
        }
    }

    .ant-form-item-control-input-content {
        line-height: 1;
    }

    .ant-form-item-explain {
        font-weight: 500;
    }

    &--label-only {
        .ant-form-item-control {
            display: none;
        }
    }
}

.dixu-form-section {
    &:not(:last-child) {
        margin-bottom: 16px;
    }

    & + & {
        @include themify {
            padding-top: 16px;
            border-top: 1px solid themed('color', 2, 4);
        }
    }
}
