.dixu-menu {
    padding: 0;
}

.dixu-menu-item {
    padding: 8px 12px;

    a {
        display: block;
        padding: 8px 12px;
        margin: -8px -12px;
    }
}

.dixu-sub-menu {
}

.dixu-sub-menu-popup {
}
