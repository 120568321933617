.dixu-card-button {
    @include themify {
        border-radius: themed('border', 'radius', 'lg');
        background: themed('color', 2, 1);
        transition: transform themed('transition', 'speed', 'md');
    }

    border: 0;
    outline: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    padding: 16px 32px;
    transform-origin: center;

    @include bp-desktop {
        justify-content: center;
    }

    &:hover:not(:disabled) {
        cursor: pointer;
    }

    &:not(:disabled) {
        &:hover,
        &:focus {
            transform: scale(1.02);
        }
    }

    &:disabled:not(#{&}--subtle-disabled) {
        @include themify {
            background: themed('color', 2, 3);
        }

        &:hover {
            cursor: not-allowed;
        }
    }

    &--loading:disabled {
        &:hover {
            cursor: wait;
        }
    }

    &__content-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        @include themify {
            color: themed('color', 1, 6);
        }

        width: 64px;
        height: 64px;
        line-height: 1;
        display: flex;
        align-items: center;
        font-size: 48px;
        padding-right: 16px;
        flex: 0 0 auto;

        & > * {
            width: 100%;
            height: auto;
        }

        & * {
            @include themify {
                fill: themed('color', 1, 6);
            }
        }
    }

    &__title {
        margin-bottom: 4px;
    }

    &:disabled:not(#{&}--subtle-disabled) &__title {
        @include themify {
            color: themed('color', 2, 6);
        }
    }

    &:disabled:not(#{&}--subtle-disabled) &__content {
        @include themify {
            color: themed('color', 2, 6);
        }
    }
}
