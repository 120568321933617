.dixu-messages-modal {
    .ant-modal-body {
        padding: 0;
    }

    &__title {
        @include themify {
            border-right: 1px solid themed('color', 2, 5);
        }

        padding: 12px 44px 0 24px;

        @include bp-tablet {
            @include themify {
                box-shadow: 0 1px 0 themed('color', 2, 5);
            }

            text-align: right;
            padding: 12px 24px;
        }
    }

    &__recipient {
        padding: 16px 24px;
    }

    &__threads {
        @include themify {
            border-right: 1px solid themed('color', 2, 5);
        }

        padding: 16px 24px;
        height: 100%;
        overflow: auto;
    }

    &__thread-button {
        @include button-reset;
    }

    &__messages {
        @include themify {
            background: themed('color', 2, 4);
            border-top: 1px solid themed('color', 2, 5);
        }

        height: 343px;
        max-height: 75vh;
        overflow: auto;

        & > * {
            min-height: 100%;
            align-content: flex-end;
            padding: 16px 24px;
        }
    }

    &__compose-message {
        @include themify {
            border-top: 1px solid themed('color', 2, 5);
        }

        padding: 10px 16px;
    }
}

.dixu-message-bubble {
    &__message {
        @include themify {
            border-radius: themed('border', 'radius', 'md');
        }

        padding: 12px;

        &--received {
            @include themify {
                background: themed('color', 2, 1);
            }

            @include important {
                border-top-left-radius: 0;
            }
        }

        &--sent {
            @include themify {
                background: themed('color', 2, 3);
            }

            @include important {
                border-bottom-right-radius: 0;
            }
        }
    }
}
