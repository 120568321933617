.dixu-listing-screen {
    &__hero {
        position: relative;

        @include bp-desktop-xl {
            min-height: 0;
        }

        & > * {
            @include bp-mobile-all-only {
                padding-bottom: 0;
            }
        }
    }

    &__carousel-wrapper {
        margin: -16px -16px 0 -16px;

        @include bp-tablet {
            margin: 0;
        }

        .dixu-carousel {
            @include bp-mobile-all-only {
                &,
                &__nav,
                &__nav-item {
                    border-radius: 0 !important;
                }

                &__nav {
                    padding-left: 4px;
                    padding-right: 4px;
                }
            }
        }
    }

    &__basic-info {
        flex: 1 1 auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__basic-info-section {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 48px;

        @include bp-desktop {
            margin-bottom: 32px;
        }

        @include bp-desktop-md {
            margin-bottom: 48px;
        }

        &:last-child {
            justify-content: flex-end;
            margin-bottom: 0;
        }

        & > * {
            margin-bottom: 48px;

            @include bp-desktop {
                margin-bottom: 16px;
            }

            @include bp-desktop-md {
                margin-bottom: 48px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__section-links {
        & > * {
            margin-bottom: 24px;
        }

        & > *:last-child {
            margin-bottom: 0;
        }

        @include bp-tablet {
            display: flex;
            overflow: auto;

            & > * {
                flex: 0 0 auto;
                margin-bottom: 0;
            }
        }

        @include bp-desktop {
            overflow: visible;
            justify-content: space-evenly;
        }
    }

    &__if-insurance-cta {
        $ifColor: #0054F0;

        &:not(:hover):not(:focus):not(:active) {
            button {
                border-color: $ifColor !important;
                color: $ifColor !important;
            }
        }

        &:hover,
        &:focus,
        &:active {
            button {
                border-color: lighten($ifColor, 20%) !important;
                color: lighten($ifColor, 20%) !important;
            }
        }
    }
}
