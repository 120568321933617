.dixu-pagination {
    & > li {
        border: 0 !important;
        background: transparent !important;
        height: auto;

        & > * {
            padding: 3px 0;
            width: 32px;
        }
    }

    // We custom render the pagination buttons
    .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next {
        min-height: unset;
        height: unset;
        line-height: unset;
        border: none;
        min-width: unset;
        background: transparent;
        border-radius: 32px;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        & > * {
            @include themify {
                border-radius: themed('border', 'radius', 'xs');
            }
        }
    }
}
