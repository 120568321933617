.dixu-modal {
    .ant-modal-content {
        @include themify {
            border-radius: themed('border', 'radius', 'md');
            background: themed('color', 2, 1);
            box-shadow: themed('shadow', 3);
        }
    }

    .ant-modal-close-icon {
        @include themify {
            color: themed('color', 2, 9);
        }
    }

    &--content-only {
        .ant-modal-content {
            @include important {
                background: transparent;
                box-shadow: none;
                border-radius: 0;
            }
        }

        .ant-modal-body {
            padding: 0;
        }

        .ant-modal-confirm-content {
            margin: 0;
        }

        .ant-modal-confirm-btns {
            display: none;
        }
    }
}
