.dixu-listing-search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include bp-desktop {
        flex-direction: row;
        min-height: 60px;
    }

    > *:not(:first-child) {
        margin-top: 1px;

        @include bp-desktop {
            margin-top: 0;
            margin-left: 1px;
        }
    }

    &__input-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;
        position: relative;
        padding: 8px 24px;
        min-width: 230px; // Prevent dropdown box from being larger than the selector

        .ant-select-selection-item {
            color: white !important;
        }

        &:has(.dixu-listing-search__room-count-input) {
            @include bp-desktop {
                min-width: 197px;
            }
        }

        &:has(.dixu-listing-search__price-input) {
            @include bp-desktop {
                min-width: 298px;
            }
        }

        & > * {
            width: 100%;
            height: 100%;
        }

        &:first-child {
            @include themify {
                border-radius: themed('border', 'radius', 'lg') themed('border', 'radius', 'lg') 0 0;
            }

            @include bp-desktop {
                width: 424px;

                @include themify {
                    border-radius: themed('border', 'radius', 'lg') 0 0
                        themed('border', 'radius', 'lg');
                }
            }
        }

        @include themify {
            background: rgba(themed('color', 2, 9), 0.8);
            transition: background themed('transition', 'speed', 'md');
        }

        &:hover,
        &:focus-within {
            @include themify {
                background: rgba(themed('color', 2, 9), 1);
            }
        }
    }

    &__text-input {
        background: transparent !important;
        border: 0 !important;
        box-shadow: none !important;
        font-weight: 600;
        padding: 18px 24px;

        @include themify {
            color: themed('color', 2, 2);
        }

        &::placeholder {
            font-weight: 400;

            @include themify {
                color: themed('color', 2, 5);
            }
        }
    }

    &__select-input {
        text-align: left;

        .ant-select-selector {
            background-color: transparent !important;
            border: 0 !important;
            box-shadow: none !important;
        }

        .ant-select-selection-item {
            font-weight: 600 !important;

            @include themify {
                color: themed('color', 2, 2);
            }
        }

        .ant-select-selection-placeholder {
            font-weight: 600;

            @include themify {
                color: themed('color', 2, 2);
            }
        }

        .ant-select-arrow {
            @include themify {
                color: themed('color', 2, 2);
            }
        }

        .ant-select-clear {
            @include important {
                background: transparent;
            }

            @include themify {
                color: themed('color', 2, 1);
            }
        }

        & ~ div {
            pointer-events: none;

            & > * {
                pointer-events: auto;
            }
        }
    }

    &__select-input-dropdown {
        box-shadow: none !important;
        left: 0 !important;
        width: 100% !important;
        top: 61px !important;
        display: table;

        @include themify {
            background: themed('color', 2, 9);
            border-radius: 0 0 themed('border', 'radius', 'lg') themed('border', 'radius', 'lg');
        }

        @include bp-desktop {
            top: 62px !important;
        }

        .ant-select-item-option {
            padding: 12px 24px !important;
        }

        .ant-select-item-option-content {
            @include themify {
                font: themed('font', 'quicksand', 'desktop', 3);
                color: themed('color', 2, 1);
            }

            @include important {
                font-weight: 600;
            }
        }

        .ant-select-item-option-selected:not(.ant-select-item-option-active) {
            .ant-select-item-option-content {
                @include themify {
                    color: themed('color', 2, 9);
                }
            }
        }
    }

    &__search-button {
        width: 100%;
        height: 56px;

        @include themify {
            border-radius: 0 0 themed('border', 'radius', 'lg') themed('border', 'radius', 'lg');
        }

        @include bp-desktop {
            width: 64px;
            height: 100%;

            @include themify {
                border-radius: 0 themed('border', 'radius', 'lg') themed('border', 'radius', 'lg') 0;
            }
        }
    }
}
