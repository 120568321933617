@mixin dixu-description-list-vertical {
    $query: nth(nth(&, 1), 1);
    $parent: nth(nth(&, -1), -1);

    @at-root #{$query}#{$parent} {
        #{$parent}__item {
            grid-template-rows: auto auto;
            grid-template-columns: minmax(0, 1fr);

            &:not(:last-of-type) {
                margin-bottom: 24px;
            }
        }

        &--with-icon #{$parent}__item {
            grid-template-columns: 32px minmax(0, 1fr);
        }

        #{$parent}__icon-wrapper {
            grid-row: 1 / span 2;
        }

        #{$parent}__name {
            padding-right: 0;
        }

        &--with-icon #{$parent}__name {
            align-self: end;
        }

        #{$parent}__value {
            grid-row: 2;
            grid-column: 1;
        }

        &--with-icon #{$parent}__value {
            grid-column: 2;
            align-self: start;
        }
    }
}

.dixu-description-list {
    margin-bottom: 0;

    &__item {
        position: relative;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 40% minmax(0, 1fr);
        gap: 0 14px;
        justify-content: start;

        &:not(:last-of-type) {
            margin-bottom: 12px;
        }
    }

    &--auto-name-width &__item {
        grid-template-columns: auto minmax(0, 1fr);
    }

    &--with-icon &__item {
        grid-template-columns: 32px 40% minmax(0, 1fr);
        min-height: 32px;
    }

    &--auto-name-width#{&}--with-icon &__item {
        grid-template-columns: 32px auto minmax(0, 1fr);
    }

    &__icon-wrapper {
        position: absolute;
        top: 50%;
        left: 0;
        width: 32px;
        height: 100%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;

        & > * {
            line-height: 0 !important;
        }
    }

    &__icon {
        line-height: 0;

        & > * {
            width: 100%;
            height: auto;
        }
    }

    &__name {
        grid-row: 1;
        grid-column: 1;
    }

    &--with-icon &__name {
        grid-row: 1;
        grid-column: 2;
        align-self: center;
    }

    &__value {
        grid-row: 1;
        grid-column: 2;
        margin-bottom: 0;
    }

    &--with-icon &__value {
        grid-column: 3;
        align-self: center;
    }

    &--vertical-true & {
        @include dixu-description-list-vertical;
    }

    &--vertical-mobile & {
        @include bp-mobile-all-only {
            @include dixu-description-list-vertical;
        }
    }

    &--vertical-tablet & {
        @include bp-tablet-all-only {
            @include dixu-description-list-vertical;
        }
    }

    &--vertical-desktop & {
        @include bp-desktop-all-only {
            @include dixu-description-list-vertical;
        }
    }
}
