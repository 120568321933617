.dixu-row {
    font-size: inherit !important;

    &--align-stretch {
        align-items: stretch;
    }

    &--align-content-middle {
        align-content: center;
    }

    &--type-inline {
        @include important {
            display: inline-flex;
        }
    }

    &--direction-column {
        @include important {
            flex-direction: column;
        }
    }

    &--wrap-reverse {
        @include important {
            flex-wrap: wrap-reverse;
        }
    }
}

.dixu-col {
    font-size: inherit !important;

    &--type-flex {
        @include important {
            display: flex;
        }

        & > * {
            flex: 1 1 auto;
        }
    }
}
