.dixu-anchor {
    &--type-tabs {
        overflow: auto;

        @include themify {
            background: themed('color', 2, 2);
        }

        scroll-behavior: smooth;

        .ant-anchor {
            display: flex;
        }

        .ant-anchor-ink {
            display: none !important;
        }

        .dixu-anchor-link {
            display: flex;
            padding: 0 16px !important;

            &:first-child {
                padding-left: 24px;
            }

            &:last-child {
                padding-right: 24px;
            }

            a {
                @include themify {
                    font: bold themed('font', 'quicksand', 'desktop', 3);
                    color: themed('color', 2, 8);
                }

                display: flex;
                align-items: center;
                border-bottom: 2px solid transparent;
                padding: 16px 0;
            }

            &.ant-anchor-link-active {
                a {
                    @include themify {
                        color: themed('color', 1, 6);
                        border-color: themed('color', 2, 7);
                    }
                }
            }
        }
    }
}

.dixu-anchor-link {
}
