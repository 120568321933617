#CybotCookiebotDialog {
    @include themify {
        background: themed('color', 2, 3) !important;
        box-shadow: themed('shadow', 7) !important;
    }

    z-index: $z-index-top !important;

    &,
    & * {
        box-sizing: border-box !important;
    }

    & * {
        @include themify {
            color: themed('color', 2, 8) !important;
        }

        font-family: Quicksand !important;
    }

    #CybotCookiebotDialogBody {
        max-width: 786px !important;
    }

    #CybotCookiebotDialogPoweredbyLink {
        height: 33px;
        background-image: url('/images/dixu_dark.svg') !important;
        background-size: contain !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
    }

    #CybotCookiebotDialogPoweredbyImage {
        visibility: hidden;
    }

    #CybotCookiebotDialogBodyContentTitle {
        @include themify {
            font: bold themed('font', 'quicksand', 'desktop', 3) !important;
            color: themed('color', 2, 8) !important;
        }
    }

    #CybotCookiebotDialogBodyContentText {
        @include themify {
            font: themed('font', 'quicksand', 'desktop', 1) !important;
            color: themed('color', 2, 8) !important;
        }
    }

    #CybotCookiebotDialogBodyButtons {
        display: flex !important;
        flex-direction: column !important;
        margin-left: -6px !important;

        @include bp-mobile-lg {
            flex-direction: row !important;
            margin-left: 0 !important;
        }

        & > * {
            margin: 12px 0 0 12px !important;
            max-width: 100% !important;
            width: auto !important;
            flex: 1 1 auto !important;
        }
    }

    #CybotCookiebotDialogBodyButtonDecline {
        @include themify {
            border-radius: themed('border', 'radius', 'md') !important;
            font: themed('font', 'quicksand', 'desktop', 2) !important;
            color: themed('color', 2, 8) !important;
            background: themed('color', 2, 1) !important;
            border: 1px solid themed('color', 2, 4) !important;
        }

        padding: 2.5px 16px !important;
        font-weight: 500 !important;
        white-space: normal !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    #CybotCookiebotDialogBodyButtonAccept {
        @include themify {
            border-radius: themed('border', 'radius', 'md') !important;
            font: themed('font', 'quicksand', 'desktop', 2) !important;
            color: themed('color', 2, 1) !important;
        }

        @include primary-button-overlay;

        padding: 2.5px 16px !important;
        font-weight: 500 !important;
        white-space: normal !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    #CybotCookiebotDialogBodyButtonDetails {
        @include themify {
            font: themed('font', 'quicksand', 'desktop', 2) !important;
            color: themed('color', 2, 8) !important;
            background-position: right 15px !important;
        }
    }

    #CybotCookiebotDialogDetail {
        margin-top: 12px !important;
    }

    #CybotCookiebotDialogDetail {
        @include themify {
            background: themed('color', 2, 3) !important;
        }
    }

    #CybotCookiebotDialogDetailFooter {
        @include themify {
            background: themed('color', 2, 3) !important;
        }
    }
}
