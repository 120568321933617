.dixu-step-list {
    margin-bottom: 0;

    &__step {
        &:not(:last-child) {
            margin-bottom: 48px;
        }
    }

    &__step-main-info {
        display: flex;
        align-items: center;
    }

    &__step-icon-wrapper {
        width: 56px;
        margin-right: 20px;
    }

    &__step-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 56px;
        height: 56px;
        font-size: 30px;
        line-height: 38px;

        &--check {
            @include themify {
                background: themed('color', 2, 1);
                border: 1px solid themed('color', 2, 6);
            }
        }
    }

    &__step-content {
        display: block;
    }

    &__step-description {
        display: block;
        margin-top: 20px;
    }
}
