.dixu-date-picker {
    display: inline-flex;
    max-width: 100%;

    .ant-picker {
        @include themify {
            border: 1px solid themed('color', 2, 4);
            border-radius: themed('border', 'radius', 'md');
            background: themed('color', 2, 2);
        }

        width: 100%;

        @at-root .dixu-form--color-scheme-reverse & {
            @include themify {
                background: themed('color', 2, 1);
            }
        }

        .ant-picker-input {
            input {
                @include themify {
                    color: themed('color', 2, 8);
                }

                &::placeholder {
                    @include themify {
                        color: themed('color', 2, 6);
                    }
                }
            }
        }

        .ant-picker-suffix {
            @include themify {
                color: themed('color', 2, 6);
            }
        }

        .ant-picker-clear {
            @include themify {
                background: themed('color', 2, 2);
            }
        }

        &:hover {
            @include themify {
                border-color: themed('color', 1, 5);
            }
        }

        &.ant-picker-focused {
            @include themify {
                border-color: themed('color', 1, 5);
                box-shadow: themed('shadow', 5);
            }
        }

        &.ant-picker-disabled {
            @include themify {
                border-color: themed('color', 2, 4);
                background: themed('color', 2, 3);
            }

            .ant-picker-input {
                input {
                    @include themify {
                        color: themed('color', 2, 6);
                    }
                }
            }
        }
    }

    &--static-open {
        flex-direction: column;

        & > div:nth-child(2) {
            position: static !important;
            max-width: 100%;
        }
    }

    &--hide-input {
        & > .ant-picker {
            visibility: hidden;
            height: 0;
            width: 0;
            overflow: hidden;
            padding: 0;
            margin: 0;
            border: 0;
        }
    }

    &--block {
        display: flex;
        flex-direction: column;
    }
}

.dixu-date-picker-dropdown {
    @include themify {
        border-radius: themed('border', 'radius', 'md');
        border: 1px solid themed('color', 2, 4);
        box-shadow: themed('shadow', 1);
    }

    overflow: hidden;
    max-width: 100vw;

    .ant-picker-header-view button {
        vertical-align: middle;
    }

    .ant-picker-panel-container {
        @include themify {
            background: themed('color', 2, 1);
        }
    }

    .ant-picker-panel {
        display: flex;
        border: 0;
    }

    .ant-picker-decade-panel,
    .ant-picker-year-panel,
    .ant-picker-month-panel,
    .ant-picker-date-panel {
        width: 100%;
    }

    .ant-picker-header {
        @include themify {
            border-bottom: 1px solid themed('color', 2, 4);
        }

        button {
            @include themify {
                font: bold themed('font', 'quicksand', 'desktop', 2);
                color: themed('color', 2, 9);
            }
        }
    }

    .ant-picker-content {
        @include important {
            width: 100%;
        }

        thead {
            th {
                @include themify {
                    font: bold themed('font', 'quicksand', 'desktop', 2);
                    color: themed('color', 2, 8);
                }
            }
        }

        tbody {
            .ant-picker-cell-inner {
                @include themify {
                    border-radius: themed('border', 'radius', 'sm');
                }

                padding: 3px 6px;
                height: auto;
                width: 100%;
                border: 1px solid transparent;
            }

            .ant-picker-cell {
                @include themify {
                    font: themed('font', 'quicksand', 'desktop', 2);
                    color: themed('color', 2, 6);
                }

                padding: 2px;

                &:hover {
                    .ant-picker-cell-inner {
                        @include themify {
                            @include important(2) {
                                background: themed('color', 1, 2);
                            }
                        }
                    }
                }
            }

            .ant-picker-cell-in-view {
                @include themify {
                    color: themed('color', 2, 8);
                }
            }

            .ant-picker-cell-today {
                @include themify {
                    font-weight: bold;
                }

                .ant-picker-cell-inner {
                    &::before {
                        @include themify {
                            border: none;
                            border-radius: inherit;
                        }
                    }
                }
            }

            .ant-picker-cell-selected {
                @include themify {
                    font-weight: bold;
                }

                .ant-picker-cell-inner {
                    @include themify {
                        background: themed('color', 1, 1);
                        border: 1px solid themed('color', 1, 6);
                        color: themed('color', 1, 6);
                    }
                }
            }
        }
    }

    .ant-picker-panel > div:not(.ant-picker-date-panel) .ant-picker-content {
        .ant-picker-cell-disabled {
            &::before {
                @include themify {
                    background: transparent;
                }
            }
        }
    }

    .ant-picker-body {
        @include important {
            padding: 16px 24px;
        }

        overflow: auto;
    }

    .ant-picker-footer {
        @include themify {
            @include important {
                border-top: 1px solid themed('color', 2, 4);
            }
        }
    }

    .ant-picker-today-btn {
        @include themify {
            font: themed('font', 'quicksand', 'desktop', 2);
            color: themed('color', 1, 6);
        }
    }

    &--static-open {
        position: static !important;
        max-width: 100%;
        animation: none !important;
        opacity: 1 !important;
        pointer-events: auto !important;
        display: contents;

        @include important {
            box-shadow: none;
        }
    }

    &--hide-header {
        .ant-picker-header {
            display: none;
        }
    }
}

.dixu-date-picker-container {
    @include bp-mobile-all-only {
        @include themify {
            transition: opacity themed('transition', 'speed', 'md');
        }

        opacity: 0;
        z-index: $z-index-top;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            position: absolute !important;
            overflow: auto;
            padding: 16px;
            background: rgba(#000, 0.45);

            & > div {
                transform-origin: center;
                margin: auto;
                max-width: 100%;
            }
        }

        .dixu-date-picker-dropdown {
            left: auto !important;
            top: auto !important;
            position: static !important;
            animation: none !important;
            opacity: 1 !important;
            pointer-events: none !important;
            max-width: 100%;
        }

        &--open {
            opacity: 1;
            pointer-events: auto;

            .dixu-date-picker-dropdown {
                pointer-events: auto !important;
            }
        }
    }
}
