.dixu-checkbox {
    display: inline-flex;
    align-items: center;

    .ant-checkbox {
        .ant-checkbox-inner {
            @include themify {
                background: themed('color', 2, 1);
                border: 1px solid themed('color', 2, 5);
            }
        }

        &:hover {
            .ant-checkbox-inner {
                @include themify {
                    border: 1px solid themed('color', 1, 5);
                }
            }
        }

        &::after {
            border: 0;
        }

        & + span {
            @include themify {
                color: themed('color', 2, 8);
            }
        }
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            @include themify {
                background: themed('color', 1, 6);
                border: 1px solid themed('color', 1, 6);
            }
        }
    }

    .ant-checkbox-disabled {
        .ant-checkbox-inner {
            @include themify {
                background: themed('color', 2, 3);
                border: 1px solid themed('color', 2, 5) !important;
            }

            &::after {
                @include themify {
                    border-color: themed('color', 2, 6);
                }
            }
        }

        & + span {
            @include themify {
                color: themed('color', 2, 6);
            }
        }
    }

    &--block {
        display: flex;

        & > span:first-of-type {
            flex: 0 0 auto;
        }

        & > span:last-of-type {
            flex: 1 1 auto;
        }
    }
}

.dixu-checkbox-button {
    .ant-checkbox {
        @include vhidden;
    }

    .ant-checkbox + span {
        @include themify {
            border-radius: themed('border', 'radius', 'md');
            font: 500 themed('font', 'quicksand', 'desktop', 2);
            transition: background themed('transition', 'speed', 'md'),
                color themed('transition', 'speed', 'md');
        }

        display: inline-block;

        padding: 4px 16px;
        user-select: none;
    }

    &--size-large {
        .ant-checkbox + span {
            @include themify {
                border-radius: themed('border', 'radius', 'lg');
                font: 500 themed('font', 'quicksand', 'desktop', 3);
            }

            padding: 7px 16px;
        }
    }

    &--type-solid {
        .ant-checkbox + span {
            @include themify {
                background: themed('color', 2, 1);
                border: 1px solid themed('color', 2, 4);
                color: themed('color', 2, 8);
            }

            position: relative;
        }

        .ant-checkbox-checked + span {
            @include primary-button-overlay;

            @include themify {
                background: themed('color', 1, 6);
                color: themed('color', 2, 1);
                border: 1px solid themed('color', 1, 6);
            }
        }
    }

    &--other {
        .ant-checkbox + span {
            padding: 3.5px 16px;
        }
    }

    &--size-large#{&}--other {
        .ant-checkbox + span {
            padding: 6.5px 16px;
        }
    }
}

.dixu-checkbox-group {
    column-gap: 8px !important;
    row-gap: 8px !important;

    &--align-center {
        @include important {
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &--adjoined {
        column-gap: 0 !important;

        @include important {
            display: inline-flex;
            flex-wrap: nowrap;
        }

        & > .dixu-checkbox-button {
            .ant-checkbox + span {
                text-align: center;
            }

            &:first-child {
                .ant-checkbox + span {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            &:not(:first-child):not(:last-child) {
                .ant-checkbox + span {
                    border-radius: 0;
                }
            }

            &:not(:first-child) {
                .ant-checkbox + span {
                    border-left-color: transparent;
                }
            }

            &:last-child {
                .ant-checkbox + span {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            &.ant-checkbox-wrapper-checked
                + .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked) {
                .ant-checkbox + span {
                    @include themify {
                        border-left-color: themed('color', 2, 4);
                    }
                }
            }
        }
    }

    &--block {
        display: block;
    }
}
