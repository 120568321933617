.dixu-switch {
    @include themify {
        background: themed('color', 2, 4);
    }

    position: relative;

    &:not(:disabled)::after {
        content: '';
        position: absolute;
        border-radius: inherit;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1),
            inset 0px -1px 0px rgba(255, 255, 255, 0.25);
    }

    &.ant-switch-checked {
        @include themify {
            background: themed('color', 1, 6);
        }
    }
}
