.dixu-collapse {
    .ant-collapse-content {
        background: transparent;
        border-top: 0;
    }

    &--type-default {
        @include themify {
            border-radius: themed('border', 'radius', 'md');
        }

        overflow: hidden;

        .ant-collapse-header {
            @include important(2) {
                padding: 16px 24px 16px 24px;
            }
        }

        .ant-collapse-content-box {
            @include important(2) {
                padding: 8px 24px 16px 24px;
            }
        }

        .ant-collapse-item {
            @include themify {
                border-bottom: 1px solid themed('color', 2, 4);
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    &--type-ghost {
        .ant-collapse-header {
            @include important(2) {
                padding: 32px 16px 32px 16px;
            }
        }

        .ant-collapse-content {
            .ant-collapse-content-box {
                @include important(2) {
                    padding-top: 0;
                    padding-bottom: 32px;
                }
            }
        }
    }

    &--bordered#{&}--type-default {
        @include themify {
            border: 1px solid themed('color', 2, 4);
        }
    }

    &--bordered#{&}--type-ghost {
        .dixu-collapse-panel {
            @include themify {
                border-bottom: 2px solid rgba(themed('color', 2, 7), 0.1) !important;
            }
        }
    }

    &--no-horizontal-padding {
        overflow: visible;

        .ant-collapse-header {
            @include important(2) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .ant-collapse-content {
            overflow: visible;
        }

        .ant-collapse-content-box {
            @include important(2) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .ant-collapse-expand-icon {
        align-self: center;
    }

    &__expand-icon-wrapper {
        @include themify {
            transition: transform themed('transition', 'speed', 'md');
        }

        @include important(3) {
            bottom: 0;
            top: 0;
            display: flex;
            align-items: center;
            padding: 0;
        }

        &--active {
            transform: rotate(90deg);
        }
    }

    &--no-horizontal-padding &__expand-icon-wrapper {
        @include important(3) {
            right: 0;
        }
    }
}

.dixu-collapse-panel {
}
