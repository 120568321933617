@keyframes dixu-main-nav-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.dixu-main-nav {
    @include themify {
        transition: transform themed('transition', 'speed', 'md'),
            background themed('transition', 'speed', 'md');
    }

    z-index: $z-index-high;
    top: 0;
    left: 0;
    transform: translateY(0);
    width: 100%;
    background: transparent;
    overflow: hidden;

    &--hidden {
        transform: translateY(-100%);
    }

    &--reserve-space {
        position: sticky;
    }

    &--position-fixed {
        position: fixed;
    }

    &--position-sticky {
        position: sticky;
    }

    &--position-static {
        position: static;
    }

    &--solid#{&}--color-scheme-light {
        @include themify {
            background: themed('color', 2, 2);
        }
    }

    &--scrolled#{&}--color-scheme-light {
        @include themify {
            background: themed('color', 2, 2);
        }
    }

    &--solid#{&}--color-scheme-dark {
        @include themify {
            background: themed('color', 2, 9);
        }
    }

    &--scrolled#{&}--color-scheme-dark {
        @include themify {
            background: themed('color', 2, 9);
        }
    }

    &--scrolled#{&}--translucent {
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
    }

    &--scrolled#{&}--color-scheme-light#{&}--translucent {
        @include themify {
            background: rgba(themed('color', 2, 2), 0.8);

            @include firefox-only {
                background: themed('color', 2, 2);
            }
        }
    }

    &--scrolled#{&}--color-scheme-dark#{&}--translucent {
        @include themify {
            background: rgba(themed('color', 2, 9), 0.8);

            @include firefox-only {
                background: themed('color', 2, 9);
            }
        }
    }

    &--open {
        height: var(--dixu-app-viewport-height);
    }

    &:focus-within {
        transform: translateY(0) !important;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo-wrapper {
        flex: 0 0 auto;
        margin-right: 32px;

        img,
        svg {
            display: block;
            height: 32px;
            max-width: 100%;
        }
    }

    &--color-scheme-light &__logo-wrapper {
        svg * {
            @include themify {
                fill: themed('color', 2, 8);
            }
        }
    }

    &--color-scheme-dark &__logo-wrapper {
        svg * {
            @include themify {
                fill: themed('color', 2, 1);
            }
        }
    }

    &__items {
        display: none;
        flex: 1 1 auto;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;

        @include bp-desktop {
            display: flex;
        }
    }

    &__item-group {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin-bottom: 0;

        &:not(:last-of-type) {
            margin-right: 24px;
        }
    }

    &__item {
        &:not(:last-of-type) {
            margin-right: 24px;
        }
    }

    &__actions {
        display: none;
        flex: 0 0 auto;
        margin-left: 32px;

        @include bp-desktop {
            display: block;
        }
    }

    &__menu-button {
        margin-left: 24px;
    }

    &:not(#{&}--open) &__menu-button {
        @include bp-desktop {
            display: none;
        }
    }

    &__menu-container {
        @include themify {
            animation: dixu-main-nav-fade-in themed('transition', 'speed', 'md') forwards;
        }

        height: var(--dixu-main-nav-open-height);
        overflow: auto;
    }
}
