.dixu-time-picker {
    @include themify {
        border: 1px solid themed('color', 2, 4);
        border-radius: themed('border', 'radius', 'md');
        background: themed('color', 2, 2);
    }

    @at-root .dixu-form--color-scheme-reverse & {
        @include themify {
            background: themed('color', 2, 1);
        }
    }

    .ant-picker-input {
        input {
            @include themify {
                color: themed('color', 2, 8);
            }

            &::placeholder {
                @include themify {
                    color: themed('color', 2, 6);
                }
            }
        }
    }

    .ant-picker-suffix {
        @include themify {
            color: themed('color', 2, 6);
        }
    }

    .ant-picker-clear {
        @include themify {
            background: themed('color', 2, 2);
        }
    }

    @at-root .dixu-form--color-scheme-reverse & .ant-picker-clear {
        @include themify {
            background: themed('color', 2, 1);
        }
    }

    &:hover {
        @include themify {
            border-color: themed('color', 1, 5);
        }
    }

    &.ant-picker-focused {
        @include themify {
            border-color: themed('color', 1, 5);
            box-shadow: themed('shadow', 5);
        }
    }

    &.ant-picker-disabled {
        @include themify {
            border-color: themed('color', 2, 4);
            background: themed('color', 2, 3);
        }

        .ant-picker-input {
            input {
                @include themify {
                    color: themed('color', 2, 6);
                }
            }
        }
    }

    .ant-picker-active-bar {
        @include themify {
            background: themed('color', 2, 6);
        }
    }
}

.dixu-time-picker-dropdown {
    .ant-picker-panel-container {
        @include themify {
            border: 1px solid themed('color', 2, 4);
            border-radius: themed('border', 'radius', 'md');
            box-shadow: themed('shadow', 1);
        }
    }

    .ant-picker-panel {
        border: 0;
    }

    &--range .ant-picker-panel {
        @include themify {
            border-bottom: 1px solid themed('color', 2, 4);
        }
    }

    .ant-picker-time-panel {
        @include important(2) {
            padding: 0;
        }
    }

    .ant-picker-time-panel-column:not(:first-child) {
        @include themify {
            border-left: 1px solid themed('color', 2, 4);
        }
    }

    .ant-picker-time-panel-cell-inner {
        @include themify {
            @include important(1, '.ant-picker-time-panel-cell-inner') {
                color: themed('color', 2, 8);
            }
        }
    }

    .ant-picker-range-arrow {
        &::after {
            @include themify {
                box-shadow: 1px -1px 0 themed('color', 2, 4);
            }
        }
    }

    &:not(#{&}--range) {
        .ant-picker-footer {
            display: none;
        }
    }

    .ant-picker-ranges {
        display: flex;
    }

    .ant-picker-ok {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;

        button {
            @include themify {
                color: themed('color', 2, 6);
            }

            flex: 1 1 auto;
            background: transparent;
            border: 0;
            box-shadow: none;
            text-shadow: none;

            &:disabled {
                opacity: 0.5;
            }
        }
    }
}

.dixu-time-picker-container {
    & > div {
        width: auto !important;
    }

    @include bp-mobile-all-only {
        @include themify {
            transition: opacity themed('transition', 'speed', 'md');
        }

        opacity: 0;
        z-index: $z-index-top;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100% !important;
            height: 100%;
            position: absolute !important;
            overflow: auto;
            padding: 16px;
            background: rgba(#000, 0.45);

            & > div {
                transform-origin: center;
                margin: auto;
                max-width: 100%;
            }
        }

        .dixu-time-picker-dropdown {
            left: auto !important;
            top: auto !important;
            position: static !important;
            animation: none !important;
            opacity: 1 !important;
            pointer-events: none !important;
            max-width: 100%;
        }

        .ant-picker-time-panel-column {
            overflow: auto;
        }

        &--open {
            opacity: 1;
            pointer-events: auto;

            .dixu-time-picker-dropdown {
                pointer-events: auto !important;
            }
        }
    }
}
