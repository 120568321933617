.dixu-spin-wrapper {
    &--full-opacity {
        .dixu-spin {
            z-index: 11 !important;
        }

        .ant-spin-blur {
            opacity: 1 !important;

            &::after {
                opacity: 1 !important;
            }
        }
    }

    &.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
        margin: 0;
        transform: translate(-50%, -50%);
    }
}

.dixu-spin {
    @include important(2) {
        max-height: none;
    }
}
