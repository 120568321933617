.dixu-for-seller-hero {
    position: relative;
    background: linear-gradient(
            180deg,
            rgba(81, 79, 112, 0.8) 0%,
            rgba(81, 79, 112, 0.9) 50%,
            #2f2e41 100%
        ),
        url('/images/buildings_1_mobile.jpg') no-repeat center top;
    background-size: cover;
    padding-top: 64px !important;

    @include bp-mobile-all-only {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    @include bp-tablet {
        padding-top: 90px !important;
    }

    @include bp-desktop {
        background: linear-gradient(
                180deg,
                rgba(81, 79, 112, 0.8) 0%,
                rgba(81, 79, 112, 0.9) 50%,
                #2f2e41 100%
            ),
            url('/images/buildings_1.jpg') no-repeat center top;
        background-size: cover;
    }

    &__content {
        padding-top: 48px !important;

        @include bp-tablet {
            padding-top: 127px;
        }

        @include bp-desktop {
            padding-top: 0 !important;
        }
    }

    &__illustration {
        width: 148px;
        height: 192px;

        @include bp-tablet {
            width: 170px;
            height: 220px;
        }

        @include bp-desktop {
            width: 235px;
            height: 305px;
        }
    }

    &__cta-buttons {
        justify-content: center;

        @include bp-desktop {
            justify-content: flex-start;
        }
    }

    &__lift-rating {
        font-size: clamp(35px, 11vw, 48px) !important;
        line-height: 1 !important;
    }

    &__lift-name {
        font-size: clamp(13px, 4vw, 16px) !important;
        line-height: 1 !important;
    }
}
