@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/Quicksand-Regular.ttf');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/Quicksand-Regular.ttf');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/Quicksand-Medium.ttf');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/Quicksand-SemiBold.ttf');
}

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/Quicksand-Bold.ttf');
}
