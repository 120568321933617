/**
 * Styles for nprogress loading indicator (see pages/_app.js).
 */

@keyframes dixu-page-load-indicator-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.dixu-page-load-indicator {
    pointer-events: none;
    position: fixed;
    z-index: $z-index-top;

    &--bar {
        @include themify {
            background: themed('color', 1, 6);
            box-shadow: themed('shadow', 6);
        }

        top: 0;
        left: 0;
        width: 100%;
        height: 8px;
    }

    &--spinner {
        display: block;
        left: 16px;
        bottom: 16px;
        display: none;
    }

    &__peg {
        display: block;
        position: absolute;
        right: 0px;
        width: 100px;
        height: 100%;
        opacity: 1;
        transform: rotate(3deg) translate(0px, -4px);
        display: none;
    }

    &__spinner-icon {
        @include themify {
            border-top-color: themed('color', 2, 4);
            border-left-color: themed('color', 2, 4);
            border-bottom-color: themed('color', 2, 6);
            border-right-color: themed('color', 2, 6);
            animation: dixu-page-load-indicator-spinner themed('transition', 'speed', 'xl') linear
                infinite;
        }

        width: 24px;
        height: 24px;
        box-sizing: border-box;
        border-style: solid;
        border-width: 2px;
        border-radius: 50%;
    }
}
