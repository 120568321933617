.dixu-notification {
    width: 419px;

    .ant-notification-notice-message {
        @include themify {
            font: themed('font', 'quicksand', 'desktop', 3);
            color: themed('color', 2, 9);
        }
    }

    .ant-notification-notice-description {
        @include themify {
            font: themed('font', 'quicksand', 'desktop', 2);
            color: themed('color', 2, 8);
        }
    }
}
