.dixu-image {
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    max-width: 100%;

    &__drag-clone {
        .image-description-wrapper {
            visibility: hidden;
        }
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &--draggable {
        img {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            pointer-events: none;
        }
    }

    &__alt {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        user-select: none;
        pointer-events: none;
        padding: 8px;
    }

    &__controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        pointer-events: none;
    }

    &__control {
        position: absolute;
        pointer-events: auto;

        &--up {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -150%);

            @include bp-tablet {
                display: none;
            }
        }

        &--left {
            top: 50%;
            left: 50%;
            transform: translate(-200%, -50%);

            @include bp-mobile-all-only {
                display: none;
            }
        }

        &--add {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &--down {
            top: 50%;
            left: 50%;
            transform: translate(-50%, 50%);

            @include bp-tablet {
                display: none;
            }
        }

        &--right {
            top: 50%;
            left: 50%;
            transform: translate(100%, -50%);

            @include bp-mobile-all-only {
                display: none;
            }
        }

        &--delete {
            top: 8px;
            right: 8px;
        }
    }

    &--fit {
        &-contain {
            img {
                object-fit: contain;
            }
        }

        &-scale-down {
            img {
                object-fit: scale-down;
            }
        }
    }

    &--position {
        &-left {
            img {
                object-position: left center;
            }
        }

        &-center {
            img {
                object-position: center;
            }
        }

        &-right {
            img {
                object-position: right center;
            }
        }

        &-bottom {
            img {
                object-position: bottom;
            }
        }
    }

    &--type-circle {
        padding-top: 100%;
        border-radius: 50%;
    }
}
