.dixu-slider {
    .ant-slider-rail {
        @include themify {
            background: themed('color', 2, 3);
        }

        box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.15);
    }

    .ant-slider-track {
        @include themify {
            background: themed('color', 1, 6);
        }
    }

    .ant-slider-handle {
        &:focus {
            @include important {
                box-shadow: none;
            }
        }
    }

    &:hover,
    &:active {
        .ant-slider-rail {
            @include themify {
                background: themed('color', 2, 3);
            }
        }

        .ant-slider-track {
            @include themify {
                background: themed('color', 1, 6);
            }
        }

        .ant-slider-handle {
            &:not(.ant-tooltip-open) {
                @include themify {
                    border-color: themed('color', 1, 6);
                }
            }
        }
    }
}
