.dixu-price-estimate-input {
    &__ranges {
        max-width: 191px;
    }

    &__range {
        @include button-reset;

        @include themify {
            border-radius: themed('border', 'radius', 'sm');
            transition: all themed('transition', 'speed', 'md');
        }

        padding: 8px 12px;
        border: 1px solid transparent;

        &:hover,
        &:focus {
            @include themify {
                border: 1px solid themed('color', 1, 5);
                box-shadow: themed('shadow', 1);
            }
        }

        &--active {
            @include themify {
                border: 1px solid themed('color', 1, 5);
                box-shadow: themed('shadow', 1);

                &,
                &:hover,
                &:focus {
                    background: themed('color', 2, 1);
                }
            }
        }
    }

    &__slider {
        @include important {
            height: 50%;
        }

        transform-origin: center top;
        transform: scale(2);
    }
}
