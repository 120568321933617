.dixu-feature-compare {
    &__table-wrapper {
        overflow-x: auto;
    }

    table {
        width: 100%;
    }

    &,
    table,
    tbody,
    tr,
    th {
        height: 100%;
    }

    th,
    td {
        text-align: left;
    }

    &__feature-header {
        &--expandable {
            cursor: pointer;
            outline: 0;
        }

        .anticon-right {
            svg {
                @include themify {
                    transition: transform themed('transition', 'speed', 'md');
                }
            }
        }
    }

    &--mobile &__feature {
        &:nth-child(2n + 1) {
            & > * {
                background: rgba(213, 212, 232, 0.15);
            }
        }
    }

    &--mobile &__feature-header {
        @include themify {
            transition: border-bottom themed('transition', 'speed', 'md');
        }

        border-bottom: 2px solid transparent;
        outline: 0;
        hyphens: auto;
        overflow-wrap: anywhere;
    }

    &--mobile &__feature-content-wrapper {
        & > div {
            & > div {
                @include themify {
                    transition: box-shadow themed('transition', 'speed', 'md');
                }

                box-shadow: 0 -2px 0 transparent;
            }
        }

        &--expanded {
            & > div {
                & > div {
                    @include themify {
                        box-shadow: 0 -2px 0 themed('color', 2, 4);
                    }
                }
            }
        }
    }

    &--type-default {
        thead {
            th {
                padding-bottom: 30px;
                vertical-align: top;

                &:not(:first-child) {
                    text-align: center;
                }

                &:first-child {
                    padding-left: 24px;
                    padding-right: 8px;
                }

                &:not(:first-child):not(:last-child) {
                    padding-left: 8px;
                    padding-right: 8px;
                }

                &:last-child {
                    padding-right: 24px;
                    padding-left: 8px;
                }

                & > span {
                    display: inline-flex;
                    height: 100%;
                    align-items: center;
                }

                @include bp-desktop {
                    padding-bottom: 37px;
                }
            }
        }

        tbody {
            tr:nth-child(4n-3),
            tr:nth-child(4n-2) {
                &:not(.dixu-feature-compare__footer-row) {
                    th,
                    td {
                        background: rgba(213, 212, 232, 0.15);
                    }
                }
            }

            tr:nth-child(4n-3) {
                th {
                    &:first-child {
                        @include themify {
                            border-top-left-radius: themed('border', 'radius', 'sm');
                        }
                    }

                    &:last-child {
                        @include themify {
                            border-top-right-radius: themed('border', 'radius', 'sm');
                        }
                    }
                }
            }

            tr:nth-child(4n-2) {
                td {
                    &:first-child {
                        @include themify {
                            border-bottom-left-radius: themed('border', 'radius', 'sm');
                        }
                    }

                    &:last-child {
                        @include themify {
                            border-bottom-right-radius: themed('border', 'radius', 'sm');
                        }
                    }
                }
            }
        }
    }

    &--type-default &__feature-header {
        & > th {
            padding: 0 24px;
            vertical-align: top;

            &:first-child {
                padding-right: 0;
            }

            &:not(:first-child):not(:last-child) {
                padding: 0;
            }

            &:last-child {
                padding-left: 0;
            }

            & > span {
                @include themify {
                    transition: box-shadow themed('transition', 'speed', 'md');
                }

                display: inline-flex;
                width: 100%;
                height: 100%;
                padding: 24px 0 16px;
                align-items: center;
                box-shadow: 0 -2px 0 transparent inset;
            }

            &:not(:first-child) {
                & > span {
                    justify-content: center;
                    text-align: center;
                }
            }

            &:first-child {
                & > span {
                    padding-right: 8px;
                }
            }

            &:not(:first-child):not(:last-child) {
                & > span {
                    padding-left: 8px;
                    padding-right: 8px;
                }
            }

            &:last-child {
                & > span {
                    padding-left: 8px;
                }
            }
        }

        &--expanded > th {
            & > span {
                @include themify {
                    box-shadow: 0 -2px 0 themed('color', 2, 4) inset;
                }
            }
        }
    }

    &--type-default &__header-wrapper {
        display: block;
        width: 100%;
    }

    &--type-default &__feature-description-row {
        td {
            @include themify {
                height: themed('border', 'radius', 'sm');
            }

            @include firefox-only {
                height: 0 !important;
            }

            & > div {
                display: block !important;

                & > div {
                    @include themify {
                        transition: margin-top themed('transition', 'speed', 'md');
                        transition-property: margin-top, top;
                    }

                    position: relative;
                    padding: 16px 24px 24px 24px;
                    top: 40px;
                    margin-top: -40px;
                }
            }
        }

        &--expanded {
            & > td {
                & > div {
                    & > div {
                        top: 0;
                        margin-top: 0;
                    }
                }
            }
        }
    }

    &--type-default &__footer-row {
        td {
            padding-top: 35px;
            padding-bottom: 35px;

            &:not(:first-child) {
                text-align: center;
            }

            &:first-child {
                padding-left: 24px;
                padding-right: 8px;
            }

            &:not(:first-child):not(:last-child) {
                padding-left: 8px;
                padding-right: 8px;
            }

            &:last-child {
                padding-left: 8px;
                padding-right: 24px;
            }
        }
    }

    &--type-compact {
        th,
        td {
            padding: 12px 12px;
            vertical-align: middle;

            @include bp-tablet {
                padding: 12px 18px;
            }

            &:not(:first-child) {
                text-align: center;
                padding-left: 0;
            }
        }

        tbody {
            tr:nth-child(4n-3) {
                @include themify {
                    background: rgba(themed('color', 2, 1), 0.06);
                }

                th {
                    &:first-child {
                        @include themify {
                            border-top-left-radius: themed('border', 'radius', 'sm');
                            border-bottom-left-radius: themed('border', 'radius', 'sm');
                        }
                    }

                    &:last-child {
                        @include themify {
                            border-top-right-radius: themed('border', 'radius', 'sm');
                            border-bottom-right-radius: themed('border', 'radius', 'sm');
                        }
                    }
                }
            }

            tr:nth-child(4n-2) {
                @include themify {
                    background: rgba(themed('color', 2, 1), 0.06);
                }

                td {
                    &:first-child {
                        @include themify {
                            border-bottom-left-radius: themed('border', 'radius', 'sm');
                        }
                    }

                    &:last-child {
                        @include themify {
                            border-bottom-right-radius: themed('border', 'radius', 'sm');
                        }
                    }
                }
            }
        }
    }

    &--type-compact &__feature-description-row {
        &--no-content {
            display: none;
        }
    }
}
