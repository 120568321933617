@keyframes dixu-carousel-fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.dixu-carousel {
    $nav-fullscreen-height: #{'max(6rem, 12.5%)'};
    $slide-fullscreen-height: #{'calc(100% - ' + $nav-fullscreen-height + ')'};

    position: relative;
    outline: 0;
    user-select: none;
    z-index: 0;

    &__image-description-wrapper {
        width: fit-content !important;
        max-width: 60%;
        max-height: 75px;
        overflow: auto;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 8px;
        padding: 12px;
        position: absolute;
        bottom: 20px;
        @include themify {
            background: rgba(themed('color', 2, 9), 0.7);
        }

        @include bp-mobile-all-only {
            .dixu-text {
                @include important {
                    font-size: 10px;
                }
            }
        }
    }

    &--fullscreen {
        @include themify {
            background: themed('color', 2, 10);
        }

        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: var(--dixu-app-viewport-height);
        background: #000;
        z-index: $z-index-top;
    }

    &__slide-container {
        overflow: hidden;
        position: relative;
        z-index: 1;
    }

    &--fullscreen &__slide-container {
        height: $slide-fullscreen-height;
        display: flex;
        align-items: stretch;
    }

    &--fullscreen#{&}--no-nav &__slide-container {
        height: 100%;
    }

    &__controls-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 1;
    }

    &--video-active &__controls-container {
        height: 40%;
        top: 50%;
        transform: translateY(-50%);
    }

    &__control {
        @include themify {
            transition: opacity themed('transition', 'speed', 'md');
            color: themed('color', 2, 1);
            font: themed('font', 'quicksand', 'desktop', 6);

            svg {
                filter: drop-shadow(themed('shadow', 8));
            }
        }

        position: absolute;
        background: transparent;
        border: 0;
        padding: 16px;
        cursor: pointer;
        outline: 0;
        pointer-events: auto;
        opacity: 0.5;

        &:hover,
        &:focus {
            opacity: 1;
        }

        @include bp-tablet {
            padding: 24px;
        }

        &--right {
            top: 0;
            right: 0;
            height: 100%;
        }

        &--left {
            top: 0;
            left: 0;
            height: 100%;
        }

        &--bottom-right {
            right: 0;
            bottom: 0;
        }
    }

    &--video-active &__control {
        opacity: 0;

        &:hover,
        &:focus {
            opacity: 1;
        }

        &--bottom-right {
            display: none;
        }
    }

    &__slide {
        @include themify {
            background: themed('color', 2, 2);
        }

        position: relative;

        &--prev {
            @include themify {
                animation: dixu-carousel-fade-out themed('transition', 'speed', 'md') forwards;
            }

            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            pointer-events: none;
        }
    }

    &--fullscreen &__slide {
        flex: 1 1 auto;
        background: transparent;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__nav {
        @include themify {
            border-bottom-left-radius: themed('border', 'radius', 'lg');
            border-bottom-right-radius: themed('border', 'radius', 'lg');
        }

        display: flex;
        align-items: stretch;
        overflow: hidden;
        height: 96px;

        @include bp-tablet {
            height: 128px;
        }
    }

    &--fullscreen &__nav {
        @include themify {
            background: themed('color', 2, 1);
        }

        height: $nav-fullscreen-height;
        padding: 0 4px 4px 4px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &__nav-items {
        position: relative;
        flex: 0 0 100%;
        display: flex;
        align-items: stretch;
        margin-bottom: 0;
        overflow: auto;
        scroll-behavior: smooth;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__nav-item {
        @include themify {
            transition: box-shadow themed('transition', 'speed', 'md');
        }

        flex: 1 0 (100% / 3.5);
        display: flex;
        justify-content: center;
        align-items: stretch;
        position: relative;
        overflow: hidden;
        margin: 4px 4px 0 0;
        box-shadow: 0 0 0 4px transparent inset;

        &:last-child {
            margin-right: 0;
        }

        @include bp-tablet {
            flex: 1 0 (100% / 4.5);
        }

        &:first-child {
            @include themify {
                border-bottom-left-radius: themed('border', 'radius', 'lg');
            }
        }

        &:last-child {
            @include themify {
                border-bottom-right-radius: themed('border', 'radius', 'lg');
            }
        }

        &::after {
            box-shadow: inherit;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            border-radius: inherit;
        }

        &--active {
            @include themify {
                box-shadow: 0 0 0 4px themed('color', 2, 8) inset;
            }
        }

        &--sticky {
            @include themify {
                background: themed('color', 2, 9);
                box-shadow: 4px 0 0 themed('color', 2, 1);
            }

            position: sticky;
            top: 0;
            left: 0;
            z-index: 2;

            @include safari-only {
                position: static;
                box-shadow: none !important;
            }
        }
    }

    &--fullscreen &__nav-item {
        @include bp-desktop {
            flex: 1 0 (100% / 5.5);
        }

        &:first-child {
            @include themify {
                border-bottom-left-radius: 0;
            }
        }

        &:last-child {
            @include themify {
                border-bottom-right-radius: 0;
            }
        }

        &--sticky {
            display: none;
        }
    }

    &__nav-button {
        @include themify {
            color: themed('color', 2, 1);
        }

        flex: 1 1 auto;
        background: transparent;
        border: 0;
        padding: 0;
        cursor: pointer;
        outline: 0;
        display: flex;
        justify-content: center;
        align-items: stretch;
        text-align: center;
        position: relative;
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: #{'min(3vw, 16px)'};
        overflow: hidden;

        a {
            @include themify {
                color: themed('color', 2, 1) !important;
            }
        }

        & > * {
            flex: 1 1 auto;
        }

        & > img {
            position: absolute;
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            object-fit: cover;
            object-position: center;
        }
    }

    &__nav-button-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        user-select: none;
        overflow: hidden;
    }
}
