.dixu-download-file-button {
    @include themify {
        border: 1px solid themed('color', 2, 4);
    }

    display: inline-block;
    padding: 12px;

    &--block {
        display: block;
    }
}
