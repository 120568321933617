:root {
    /**
     * Initial value is set for --dixu-app-viewport-height to prevent visual
     * "jumping" where the value is used. Value is updated in DixuApp.
     */
    --dixu-app-viewport-height: 100vh;
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    /**
     * This fixes large letter spacing with numbers when using Ropa Soft Pro
     * font on Chrome and Safari.
     */
    font-feature-settings: 'tnum' 0;
}

html {
    font-size: 100% !important;

    /**
     * Root font size is increased for larger displays and since CSS units are
     * converted to rem, all styles should scale along with them.
     */

    @include bp-desktop-xl {
        font-size: 133% !important;
    }

    @include bp-desktop-xxl {
        font-size: 200% !important;
    }
}

body {
    @include themify {
        background: themed('color', 2, 2) !important;
    }

    font-family: Quicksand, sans-serif;
    font-weight: 500;
}

/**
 * Hide input type="number" spinners.
 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/**
 * Hide input type="number" spinners on Firefox.
 */
input[type='number'] {
    -moz-appearance: textfield;
}

/**
 * Reset button style (mainly for cases where useCollapse hook is used).
 */
[type='reset'],
[type='submit'],
button,
html [type='button'] {
    -webkit-appearance: none;
}